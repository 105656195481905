import React from 'react';
import * as footerStyles from 'styles/footer.module.scss';

import Container from '@mui/material/Container';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Grid } from '@mui/material';

import {useIntl} from 'gatsby-plugin-intl';
import { CivicaLogo } from 'components/layout/CivicaLogo';
import CivicaVersion from 'components/layout/footer/CivicaVersion';

const Footer = ({inverseBackground}) => {
    const intl = useIntl()
    return (
        <footer className={footerStyles.footer}>
            <Container  className={footerStyles.container}>
                <div className={footerStyles.main}>
                    <div className={footerStyles.navigation}>
                        <a className={footerStyles.navLink}
                            href="mailto:mycivica@civica.eu">
                                <MailOutlineIcon/>
                                {intl.formatMessage({ id: "footer.navigation.contact" })}</a>

                        <a className={footerStyles.navLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://docs.google.com/document/d/e/2PACX-1vTvclMHF8rmhmIK65b4OxFv_myU5wgEpXi-6TaeFmO_zM9FEWyc4lHF2KiCujIz_eDnf6_ejHWCibBU/pub#h.xlyy4n3rcv4m">{intl.formatMessage({ id: "footer.navigation.legal" })}</a>
                    </div>
                    <div className={footerStyles.logo}>
                        <CivicaLogo />
                    </div>
                </div>
                <div  className={`${footerStyles.bottom} ${!!inverseBackground? footerStyles.greyBackground: footerStyles.whiteBackground}`}>
                    <Grid container justifyContent="flex-end" spacing={4}>
                        <Grid item>
                            <CivicaVersion />
                        </Grid>
                    </Grid>
                </div>
            </Container>
        </footer>
    )
}

export default Footer
