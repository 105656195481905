import React from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

import { useIntl } from "gatsby-plugin-intl";


const CvcConfirmDeleteDialog = ( { 
          open, onDelete, onClose , onCancel,
          itemToDeleteLabel = null, 
          dialogTitle = "deleteConfirmationDialog.dialogTitle",
          dialogContentText = "deleteConfirmationDialog.dialogContentText.text" } 
) => {  
    const intl = useIntl();
    return (
      <div>
        <Dialog
            maxWidth={"sm"}
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
              style: {
                  whiteSpace:  'pre-wrap',
              },
           }}
        >
          <DialogTitle id="alert-dialog-title">
            {intl.formatMessage({id: dialogTitle})}
          </DialogTitle>
          
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
            { itemToDeleteLabel ? 
              (intl.formatMessage({id: "deleteConfirmationDialog.dialogContentText.selectedItem" }) + itemToDeleteLabel + ".\n\n")
              : ""
            }
            { intl.formatMessage({id: dialogContentText })}
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button onClick={onCancel} autoFocus>{intl.formatMessage({id: "deleteConfirmationDialog.dialogActionCancel"})}</Button>
            <Button onClick={onDelete}>{intl.formatMessage({id: "deleteConfirmationDialog.dialogActionDelete"})}</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  export default CvcConfirmDeleteDialog;