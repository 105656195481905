import React from "react";
import {useDispatch, useSelector} from 'react-redux';

import { MenuItem, TextField, Chip } from "@mui/material";
import {Controller} from "react-hook-form";
import {useIntl} from "gatsby-plugin-intl";

import {fetchListOfValues, GROUP_STATE} from "state/common/listOfValueSlice";



const RhfListOfValuesCombo = ({
                                name,
                                group,
                                control,
                                label,
                                addItemNone = false,
                                itemNoneLabel = "",
                                useInlt = false,
                                defaultValue = "",
                                className = null,
                                error = null,
                                onBlurField = null,
                                onChangeField = null,
                                index = 0,
                                ...props
                               }) => {

    const selector = GROUP_STATE[group].state;
    const values = useSelector(selector);
    const dispatch = useDispatch();
    const intl = useIntl();

    React.useEffect(() => {
        let mounted = true;

        async function fetchData() {
            try {
                if (mounted && values.length === 0) {
                    dispatch(fetchListOfValues(group));
                }
            } catch (error) {
            }
        }

        fetchData();
        return () => (mounted = false);
    }, [group, values, dispatch, fetchListOfValues]);


    const noValuesMessage = "no "+group+" values";

    return (values && values.length > 0 ? 
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                render={({field}) => (
                    <TextField
                        {...field}
                        {...props}
                        select
                        label={intl.formatMessage({id: label})}
                        className={className}
                        variant="standard"
                        defaultChecked={defaultValue}
                        helperText={
                            error ? intl.formatMessage({id: error.message}) : null
                        }
                        onChange={(e) => {
                            field.onChange(e);
                            if (onChangeField) {
                                onChangeField(e.target);
                            }
                        }}
                        onBlur={(e) => {
                            field.onBlur();
                            if (onBlurField) {
                                onBlurField(index, field.value)
                            }
                        }}
                        error={!!error}
                    >
                        <MenuItem key={name + '_empty'} value="">
                            { intl.formatMessage({ id: "lov.empty" }) }
                        </MenuItem>

                        { addItemNone &&
                            <MenuItem key={name + '_none'} value="none">
                               { itemNoneLabel }
                            </MenuItem>
                        }

                        {   
                            values.map((value, idx) => {
                                return value && (
                                    <MenuItem key={idx} value={value.value}>
                                        { useInlt ? intl.formatMessage({ id: value.label}) :  value.label }
                                    </MenuItem>
                                );
                            })
                        }
                    </TextField>
                )}
            />
        : 
        <Chip label={noValuesMessage} variant="outlined"/>
    );
};


export default RhfListOfValuesCombo;
