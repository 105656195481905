import React, { useEffect, useRef } from "react";

import { VariableSizeList as List } from "react-window";
import Paper from '@mui/material/Paper';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';

const CvcCheckboxListVirtualized = ({ 
  items, 
  checkedItems, onCheckItems, 
  width = '100%', height = '100%', listHeight = 440
}) => {

  const listRef = useRef({});
  const rowHeights = useRef({});

  
  function getRowHeight(index) {
    return rowHeights.current[index] + 8 || 82;
  }

  function Row ({ index, style }) {
    const rowRef = useRef({});

    useEffect(() => {
      if (rowRef.current) {
        setRowHeight(index, rowRef.current.clientHeight);
      }
    }, [rowRef]);
    
    return (
    <div style={style}>
        <div ref={rowRef}>     
            <ListItemButton
                key={items[index].id}
                role="listitem"                            
                onClick={onCheckItems(items[index])}
            >
                <ListItemIcon>
                    <Checkbox
                        checked={checkedItems.includes(items[index])}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{
                            'aria-labelledby': items[index].id,
                        }}
                    />
                </ListItemIcon>
                <ListItemText primary={items[index].label} />
            </ListItemButton>
        </div>
    </div>
    );
}

  function setRowHeight(index, size) {
    listRef.current.resetAfterIndex(0);
    rowHeights.current = { ...rowHeights.current, [index]: size };
  }

  return (
    <Paper sx={{ overflow: 'auto' }} 
          style={{ width: width, height: height }}
    >    
      <List
            ref={listRef}
            className="List"
            height={listHeight}
            width={'100%'}
            itemCount={items.length}
            itemSize={getRowHeight}            
      >
            {Row}
      </List>
    </Paper>
  );
};

export default CvcCheckboxListVirtualized;
