import * as React from 'react';
import { useState } from 'react';

import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

const filter = createFilterOptions();

const CvcAutocompleteWithAdd = ({   label, 
                                    value, 
                                    options, 
                                    getInputUserOption, 
                                    getOptionLabel, 
                                    onChange, 
                                    sx = { width: 300 } 
                                }) =>
{
    const [internValue, setInternValue] = useState(null);
    return (
        <Autocomplete
            
            freeSolo
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys

            sx={sx}
            value={value}
            options={options}

            onChange={(event, newValue) => {
                let newInternValue = null;
                if (typeof newValue === 'string') {
                    newInternValue = { label: newValue };
                } else if (newValue && newValue.inputValue) {
                    // Create a new value from the user input
                    newInternValue = getInputUserOption(newValue.inputValue);
                } else {
                    newInternValue = newValue;
                }  
                setInternValue(newInternValue);
                onChange(newInternValue);
            }}

            filterOptions={(options, params) => {
                const filtered = filter(options, params);
                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some((option) => inputValue === getOptionLabel(option));
                if (inputValue !== '' && !isExisting) {
                    filtered.push({
                        inputValue,
                        label: `Add "${inputValue}"`,
                    });
                }
                return filtered;
            }}

            getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                    return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                    return option.inputValue;
                }
                // Regular option
                return getOptionLabel(option);
            }}

            renderOption={(props, option) => {
                const { key, ...optionProps } = props;
                return (
                    <li key={key} {...optionProps}>
                        {getOptionLabel(option)}
                    </li>
                );
            }}
            renderInput={(params) => (
                <TextField {...params} variant='standard' label={label} />
            )}
        />
    );
}

export default CvcAutocompleteWithAdd;