import React from "react";
import { useState } from "react";
import { useEffect } from "react";

import { Grid, TextField, Typography } from "@mui/material";
import Button from "@mui/material/Button/Button";
import { useIntl } from "gatsby-plugin-intl";

import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";

import repositoryService from "services/api/repositoryService";
import { handleError } from "utils/error/error-utils";
import { RhfTextField } from "components/backoffice/common/RhfFields";
import CvcConfirmActionDialog from "ui/CvcConfirmActionDialog";
import CvcListVirtualized from "ui/CvcListVirtualized";
import CvcLoadingProgress from "ui/CvcLoadingProgress";


const validateReferenceDataToAdd = yup.object().shape({
    valueToAdd: yup
    .string()
    .trim()
    .required("error.field.required"),     
});

const AddReferenceDataTab = ({ selectedReferenceTable, setDisabledTables, showErrorMessage, showSuccessMessage }) => {

    const intl = useIntl();
    const [loadingDatas, setLoadingDatas] = useState(false);
    const [reloadDatas, setReloadDatas] = useState(true); 
    const [referenceDataList, setReferenceDataList] = useState([]);
    
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [disabledAddBtn, setDisabledAddBtn]= useState(true);  
    
    const defaultValue = { valueToAdd: ""};
    const useFormMethods = useForm({
        resolver: yupResolver(validateReferenceDataToAdd),
        defaultValues: defaultValue,
        mode: "onChange",
    });

    useEffect(() => {
        setDisabledTables(false);
    }, []);

    useEffect(() => {
        let mounted = true;
        if(mounted){
            setReloadDatas(true);
        }      
        return () => { mounted=false; };
    }, [selectedReferenceTable]);


    useEffect(() => {
        let mounted = true;
        const fetchData = async () => {
            setLoadingDatas(true);
            try {
                const result = await repositoryService.findReferenceDataByTable(selectedReferenceTable.value);
                if(mounted){                    
                    const referenceDataList = result.data.map(item => { 
                        item.label = item.label + ' ('+item.count +')';
                        return item;
                     });
                    setReferenceDataList(referenceDataList);
                }                                
            } catch (error) {
                handleError(
                    intl, error, 
                    intl.formatMessage({ id: 'backoffice.repositories.fetchValues.error'}), 
                    showErrorMessage
                );
            } finally {                
                setLoadingDatas(false);
                setReloadDatas(false);
            }
        };
        if(reloadDatas && !loadingDatas) fetchData();        
        return () => { mounted=false; };
    }, [reloadDatas]);

    const handleClickAddNewValue = async () => {
        setOpenConfirmDialog(true);
    }

    const handleCloseConfirmDialog = () => {
        setOpenConfirmDialog(false);
    }

    const handleConfirmAction = () => {
        setOpenConfirmDialog(false);
        submitForm();
    }

    const handleReloadData = () => {
        setReloadDatas(true);
    }

    const handleValueToAddChange = (e) => {
        if(e && e.target && e.target.value && e.target.value.trim() != ""){
            setDisabledAddBtn(false);
        } else {
            setDisabledAddBtn(true);
        }       
    }

    const submitForm = async () => {
        const isValid = await useFormMethods.trigger();          
        if (isValid) {
            useFormMethods.handleSubmit(handleAddReferencesDatas)();
        }
      };

    const handleAddReferencesDatas = async (form) => {
        try {
            const addReferenceDataDto = {
                referenceValueToAdd: {label: form.valueToAdd, listCode: selectedReferenceTable.value}
            }                                            
            await repositoryService.addReferenceData(addReferenceDataDto);
            showSuccessMessage(intl.formatMessage({ id: 'backoffice.repositories.creation.add.success'}));            
            setReloadDatas(true); 
            useFormMethods.setValue("valueToAdd", "");
            setDisabledAddBtn(true);    
        } catch (error) {
            handleError(
                    intl, error, 
                    intl.formatMessage({ id: 'backoffice.repositories.creation.add.error'}), 
                    showErrorMessage
                );
        }
    }


    return (<>
        { loadingDatas &&  <CvcLoadingProgress /> }
        { !loadingDatas  &&
            <Grid container gap={2} sx={{ width: '100%', height: '100%', marginTop: '20px' }}>                
                <Grid item xs={5}>
                    <Typography component="p">
                        {intl.formatMessage({ id: "backoffice.repositories.creation.list.title" })}
                    </Typography>
                    <CvcListVirtualized 
                        items={referenceDataList} 
                        showFilter={true}
                        filterLabel={intl.formatMessage({ id: "backoffice.repositories.creation.list.filter.label" })}
                        showReloadData={true} 
                        onReloadData={handleReloadData} 
                        listHeight={310}              
                    />
                </Grid>                
                <Grid item xs={6} width={"100%"} height={"100%"}>
                    <Grid container direction='column' sx={{ rowGap: 2, width: '100%', height: '100%' }}
                            justifyContent={'start'} justifyItems={'start'}
                            alignContent={'start'} alignItems={'start'}
                        >
                       <Grid item alignContent={'center'}>
                            <Typography component="p">
                                {intl.formatMessage({ id: "backoffice.repositories.creation.textfield.title" })}
                            </Typography>
                        </Grid>     
                        <Grid item>                    
                            <RhfTextField
                                label="backoffice.repositories.creation.textfield.label"
                                name="valueToAdd"
                                sx={{ width: 200 }}
                                defaultFocus={true}
                                onChange= {handleValueToAddChange}
                                control={useFormMethods.control}
                                error={useFormMethods.formState.errors ? useFormMethods.formState.errors.valueToAdd : null}
                            />
                        </Grid> 
                        <Grid item>
                            <Button variant='contained' disabled={disabledAddBtn} onClick={handleClickAddNewValue}>
                                {intl.formatMessage({ id: "backoffice.repositories.creation.btn.add" })}
                            </Button>
                            <CvcConfirmActionDialog 
                                open={openConfirmDialog} 
                                title={intl.formatMessage({id: "backoffice.repositories.creation.dialog.confirmAdd.title"})} 
                                contentText={intl.formatMessage({id: "backoffice.repositories.creation.dialog.confirmAdd.contentText"}, {valueToAdd: useFormMethods.getValues("valueToAdd")})} 
                                onClose={handleCloseConfirmDialog} 
                                cancelBtnLabel={intl.formatMessage({id: "backoffice.repositories.creation.dialog.confirmAdd.btn.cancel"})} 
                                onCancel={handleCloseConfirmDialog} 
                                actionBtnLabel={intl.formatMessage({id: "backoffice.repositories.creation.dialog.confirmAdd.btn.add"})} 
                                onAction={handleConfirmAction}  
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        } 
    </>);
}


export default AddReferenceDataTab;
