import { WSInstance } from "./axios_config";

const BATCH_API_BASE_URL = `${process.env.API_URL}/batch`;
const BATCH_LOG_API_BASE_URL = `${process.env.API_URL}/batchLog`;


const searchBatchKo = async () => {
    return await WSInstance.get(`${BATCH_API_BASE_URL}/searchLast100BatchKo`);
};

const getBatchLogByBatchId = async (batchId) => {
    return await WSInstance.get(`${BATCH_LOG_API_BASE_URL}/getBatchLogByBatchId/${batchId}`);
};


const logService = {
    searchBatchKo,
    getBatchLogByBatchId
};

export default logService;