import React, { Fragment, useEffect, useRef, useState } from "react";

import { Box, Button, Card, CardHeader, FormHelperText, Grid, Typography } from "@mui/material";
import CardContent from "@mui/material/CardContent";

import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useBeforeunload} from "react-beforeunload";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {convertFromRaw, convertToRaw, EditorState} from "draft-js";
import "draft-js/dist/Draft.css";

import { navigate, useIntl} from "gatsby-plugin-intl";
import { parseISO } from 'date-fns';

import {GROUP} from "utils/constants/back-office-constants";
import {INPUT_MAX_LENGTH} from "utils/constants/constants";
import {FIELD_CONDITION, opportunitySchema,} from "utils/opportunities-schema";

import Seo from "components/common/Seo";
import LayoutBo from "components/backoffice/common/layout/LayoutBo";
import ConfirmationDialog from "components/common/ConfirmationDialog";
import ResultSnackbars from "components/backoffice/common/ResultSnackbars";
import { RhfListOfValuesSelect, RhfSwitch, RhfTextField } from 'components/backoffice/common/RhfFields';
import RhfDatePicker from "ui/RhfDatePicker";
import RhfListOfValuesCheckboxes from "ui/RhfListOfValuesCheckboxes";
import CvcUploadImage from "ui/CvcUploadImage";

import newsService from "services/api/newsService";
import themeMuiV5 from "components/common/ThemeMuiV5";
import CustomEditor from "./CustomEditor";

const styles = {
  root: {
    width: "100%",
  },
  card: {
    maxHeight: "100%",
    backgroundColor: themeMuiV5.palette.background.paper,
  },
  cardContent: {
    backgroundColor: themeMuiV5.palette.background.paper,
  },
  cardHeader: {
    textAlign: "center",
    backgroundColor: themeMuiV5.palette.primary.main,
    color: themeMuiV5.palette.primary.contrastText,
  },
  textField: {
    width: "100%",
    marginRight: themeMuiV5.spacing(2),
    marginTop: themeMuiV5.spacing(1)
  },
  link: {
    width: "100%",
    marginRight: themeMuiV5.spacing(2),
    marginTop: themeMuiV5.spacing(1)
  },
  formControlLabel: {
    width: "100%",
    verticalAlign: "bottom",
    marginTop: themeMuiV5.spacing(1),
    marginRight: themeMuiV5.spacing(8),
  },    
  marginLeft: {
    marginLeft: themeMuiV5.spacing(3),
  }  
};



const AddEditNewsPage = ({ opportunityId }) => {

  const intl = useIntl();
  const defaultValues = useRef({
    type: "",
    fileId: null,
    coverImage: null,
    coverImageName: null,
    deleteImage: false,
    title: "",
    hook: "",
    keyword: "",
    link: "",
    startDate: new Date(),
    endDate: new Date(),
    visible: true,    
    publicProfile: [],
    publicCycle: [],        
    hasContent: false,
    deadline: null    
  });

  const methods = useForm({
    resolver: yupResolver(opportunitySchema),
    defaultValues: defaultValues.current,
    mode: "all",
  });

  const {
    reset, control, setValue, 
    formState: { errors, isDirty},
  } = methods;

  const updatedOpportunityId = useRef(opportunityId);
  const selectedFileName = methods.watch("coverImageName");


  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await newsService.searchById(opportunityId);
        const data = result.data;
        data.startDate = parseISO(data.startDate);
        data.endDate = parseISO(data.endDate);
        data.deadline = parseISO(data.deadline);        
        const newDefaultValues = Object.assign({}, defaultValues.current, data);
        if (data.content) {
          setEditorState(
            EditorState.createWithContent(
              // convertFromRaw(markdownToDraft(data.content))
              convertFromRaw(JSON.parse(data.content))
            )
          );
          newDefaultValues["hasContent"]=true;
        }       
        reset(newDefaultValues);
      } catch (error) {
        console.error('error fetching news datas', error);
        navigate("/back-office/opportunities");
      }
    };

    if (!!opportunityId) {
       fetchData();     
    }
  }, [opportunityId, reset, defaultValues]);


  const [resultMessage, setResultMessage] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  
  const [editorDirty, setEditorDirty] = useState(false);
  const [editorState, setEditorState] = useState(
    EditorState.createEmpty()
  );
  const handleEditorStateChange = (editorState) => {
    if (editorState.getCurrentContent().hasText()) {
       setValue("hasContent", true);
    } else {
      setValue("hasContent", false);
    }
    setEditorState(editorState);
    setEditorDirty(true);
    methods.trigger();
  };

  useBeforeunload((event) => {
    if (isDirty || editorDirty) {
      event.preventDefault();
      event.returnValue = "";
    }
  });  
  
  const publicProfile = methods.watch("publicProfile");
  const newsType = methods.watch("type");

  const submitForm = () => {

    setDisableSubmit(true);
    setOpenSuccess(false);
    setOpenError(false);
    if (!FIELD_CONDITION["content"].disabled(newsType)) {
      if (
        methods.getValues("link") === "" &&
        !editorState.getCurrentContent().hasText()
      ) {
        methods.setError("link", {
          type: "manual",
          message: "error.field.required",
        });
      }
    }
    resetDisabledField();
    const content = convertToRaw(editorState.getCurrentContent());
    //  const markdownString = draftToMarkdown(content);
    const contentString = editorState.getCurrentContent().hasText()? JSON.stringify(content): null;
   
    if (!!updatedOpportunityId.current) {
      newsService
        .updateOpportunity(
          updatedOpportunityId.current,
          methods.getValues(),
          contentString
        )
        .then(() => {
          setResultMessage("opportunity.update.success");
          setOpenSuccess(true);
          setDisableSubmit(false);
          methods.reset(methods.getValues());
          setEditorDirty(false);
          methods.setValue("deleteImage", false);
        })
        .catch((error) => {
          setResultMessage("opportunity.update.error");
          setOpenError(true);
          setDisableSubmit(false);
          methods.setValue("deleteImage", false);
        });
    } else {
      newsService
        .saveOpportunity(methods.getValues(), contentString)
        .then((result) => {
          updatedOpportunityId.current = result.data;
          setResultMessage("opportunity.create.success");
          setOpenSuccess(true);
          setDisableSubmit(false);
          methods.reset(methods.getValues());
          setEditorDirty(false);
          methods.setValue("deleteImage", false);
        })
        .catch((error) => {
          setResultMessage("opportunity.create.error");
          setOpenError(true);
          setDisableSubmit(false);
          methods.setValue("deleteImage", false);
        });
    }
  };

  const fieldsDisabled = ["content", "hook", "coverImage", "keyword", "link"];
  const resetDisabledField = () => {
    //prevent disabled fields from being sending on submit
    fieldsDisabled.forEach((item) => {
      if (FIELD_CONDITION[item].disabled(newsType)) {
        methods.setValue(item, "");
      }
    });
  };

  const handleCoverImageDelete = () => {
    methods.setValue("coverImage", null);
    methods.setValue("coverImageName", null);
    methods.setValue("deleteImage", true);
  };

  const handleCoverImageChange = (event) => {
    setOpenSuccess(false);
    setOpenError(false);
    const selectedFile = event?.target?.files[0];

    if (!['image/jpg', 'image/jpeg', 'image/webp', 'image/png'].includes(selectedFile.type)) {
        setResultMessage("error.image.type");
        setOpenError(true);
        return false;
    }
    const MAX_SIZE = 1000 * 1024;
    if (selectedFile.size > MAX_SIZE) {
        setResultMessage("error.image.size");
        setOpenError(true);
        return false;
    }

    setValue("coverImage", event?.target?.files);
    setValue("coverImageName", event?.target?.files[0]?.name);
  }

  const disabledAcademicCycle = publicProfile && publicProfile.filter((item) => item.code === "STUDENT").length === 0;

  return (
    <LayoutBo>
      
      <Seo
        title={intl.formatMessage({ id: "opportunity.edit" })}
        lang={intl.locale}
      />

      <Card sx={styles.card}>

        <CardHeader
          sx={styles.cardHeader}
          title={
            !!opportunityId
              ? intl.formatMessage({ id: "opportunity.edit" })
              : intl.formatMessage({ id: "opportunity.new" })
          }
        />

        <CardContent sx={styles.cardContent}>

          {/*  {errors && <p>Errors: {JSON.stringify(errors)}</p>} 
          {{getValues() && <p>Values: {JSON.stringify(getValues())}</p>} */}

          <ResultSnackbars
            openError={openError}
            openSuccess={openSuccess}
            messageId={resultMessage}
            setOpenSuccess={setOpenSuccess}
            setOpenError={setOpenError}
          />
          
          <form noValidate onSubmit={methods.handleSubmit(submitForm)}>
            <Grid container spacing={3}>

              <Grid item xs={12} md={4} xl={3}>
                <RhfListOfValuesSelect
                  id="news-type"
                  name="type"
                  label={"opportunity.type"}
                  group={GROUP.opportunityType}
                  sx={styles.textField}
                  control={methods.control}
                  required
                  error={errors ? errors.type : null}
                />
              </Grid>

              <Grid item xs={12} md={8} xl={9}>
                <RhfTextField
                  id="news-title"
                  label="opportunity.title"
                  name="title"
                  sx={styles.textField}
                  control={control}
                  required={true}
                  maxLength={INPUT_MAX_LENGTH.NEWS_TITLE}
                  error={errors ? errors.title : null}                  
                />
              </Grid>
        
              <Grid item xs={12} md={4} xl={3}>
                <RhfDatePicker
                  name="startDate"
                  label="opportunity.start.date"
                  format="dd/MM/yyyy" 
                  control={methods.control}           
                  required={true}
                  error={errors ? errors.startDate : null}
                  disablePast={false}                  
                />
              </Grid>

              <Grid item xs={12} md={4} xl={3}>
                <RhfDatePicker
                  name="endDate"
                  label="opportunity.end.date"
                  format="dd/MM/yyyy"                      
                  control={methods.control}     
                  required={true}    
                  error={errors ? errors.endDate : null}  
                />
              </Grid>

              <Grid item sm={12} md={4} xl={6}>
                <RhfSwitch
                  label="opportunity.visible"
                  name="visible"
                  sx={styles.formControlLabel}
                  control={methods.control}
                />
              </Grid>

              <Grid item xs={12} md={4} xl={3}>
                <RhfListOfValuesCheckboxes
                  name="publicProfile"
                  label="opportunity.public.profile"
                  group={GROUP.roleGroup}
                  getValues={methods.getValues}
                  control={methods.control}                  
                  required={true}
                  error={errors ? errors.publicProfile : null}                  
                />
              </Grid>

              <Grid item xs={12} md={4} xl={9}>
                <RhfListOfValuesCheckboxes
                  name="publicCycle"
                  label="opportunity.public.cycle"
                  group={GROUP.role}
                  getValues={methods.getValues}
                  control={methods.control}                  
                  required={!disabledAcademicCycle}
                  disabled={disabledAcademicCycle}
                  error={errors ? errors.publicCycle : null}
                />
              </Grid> 

              <Grid item sm={12}>
                <CustomEditor 
                  editorState={editorState}
                  handleEditorStateChange={handleEditorStateChange}   
                  error={errors ? errors.hasContent : null}              
                />
              </Grid>

              <Grid item xs={12}>
                <CvcUploadImage
                  id="news-cover-image" 
                  label="opportunity.cover.image"
                  accept="image/*"
                  selectedFileName={selectedFileName}
                  handleDeleteCoverImage={handleCoverImageDelete}
                  handleChangeCoverImage={handleCoverImageChange}
                  disabled={FIELD_CONDITION["coverImage"].disabled(newsType)}
                />
              </Grid> 

              <Grid item xs={12} md={4} xl={3}>
                <RhfTextField
                  id="news-hook"
                  label="opportunity.hook"
                  name="hook"
                  sx={styles.textField}
                  control={control}                  
                  disabled={FIELD_CONDITION["hook"].disabled(newsType)}
                  error={errors ? errors.hook : null}
                />
              </Grid>

              <Grid item xs={12} md={8} xl={9}>
                <RhfListOfValuesSelect
                  id="news-keyword"
                  name="keyword"
                  label={"opportunity.keyword"}
                  group={GROUP.keyword}
                  sx={styles.textField}
                  control={methods.control}
                  disabled={FIELD_CONDITION["keyword"].disabled(newsType)}                  
                  error={errors ? errors.keyword : null}
                />
              </Grid>                                   
             
              <Grid item xs={12}>
                <RhfTextField
                  id="news-link"
                  label="opportunity.link"
                  name="link"
                  sx={styles.link}
                  control={control}
                  disabled={FIELD_CONDITION["content"].disabled(newsType)}
                  error={errors ? errors.hook : null}
                />
              </Grid>              

              <Grid item sm={12}>
                <RhfDatePicker
                  name="deadline"
                  label="opportunity.deadline.date"
                  format="dd/MM/yyyy"
                  control={methods.control}
                  disabled={FIELD_CONDITION["deadline"].disabled(newsType)} 
                  error={errors ? errors.deadline : null}    
                  clearable={true}
                  methods={methods}                  
                />
              </Grid>

            </Grid>

            <Grid container justifyContent="center">
              <Box py={3}>
                <Button
                  color="primary"
                  type="submit"
                  variant="contained"
                  disabled={disableSubmit}
                >
                  {intl.formatMessage({ id: "opportunity.save" })}
                </Button>
                <ConfirmationDialog
                  isModified={isDirty || editorDirty}
                  listingUrl="/back-office/opportunities"
                />
              </Box>
            </Grid>

          </form>
        </CardContent>
      </Card>
    </LayoutBo>
  );
};

export default AddEditNewsPage;
