import React, { useEffect } from 'react';

import { FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Checkbox } from "@mui/material";

import {useDispatch, useSelector} from 'react-redux';
import {Controller } from "react-hook-form";
import {useIntl} from "gatsby-plugin-intl";

import courseService from "services/api/courseService";
import {fetchListOfValues, GROUP_STATE} from "state/common/listOfValueSlice";

const RhfListOfValuesCheckboxes = ({
    group,
    name,
    label,
    control,
    getValues,
    className = null,
    sxFormControl = null,
    sxFormLabel = null,
    sxFormControlLabel = null,
    required = false,
    disabled = false,
    error = null,
}) => {
    const intl = useIntl();
    const selector = GROUP_STATE[group].state;
    const values = useSelector(selector);
    const dispatch = useDispatch();

    useEffect(() => {
        async function fetchData() {
            try {
                if (values.length === 0) {
                    dispatch(fetchListOfValues(group));
                }
            } catch (error) {
                console.error(`error fetching list of values ${group} `, error);
            }
        }

        fetchData();
    }, [group, values, dispatch, fetchListOfValues]);

    const handleCheck = (checkedId) => {
        const ids = getValues(name);
        const newIds = ids?.map((v) => v.code)?.includes(checkedId)
            ? ids.filter((id) => id.code !== checkedId)
            : [...(ids ?? []), { id: null, code: checkedId }];
        return newIds;
    };

    return (
        <FormControl error={!!error} component="fieldset" className={className} sx={sxFormControl}>
            <FormLabel component="legend" sx={sxFormLabel} required={required}>
                {intl.formatMessage({ id: label })}
            </FormLabel>
            {/* <FormHelperText>{errors.item_ids?.message}</FormHelperText> */}
            <Controller
                name={name}
                render={
                    ({ field }) =>
                    (<FormGroup sx={error ? { color: "#af4731" } : {}}>
                        {values.length > 0 && values.map((item, index) => (
                            <FormControlLabel
                                sx={sxFormControlLabel}
                                control={
                                    <Checkbox
                                        disabled={disabled}
                                        color="primary"
                                        onChange={() => {
                                            field.onChange(handleCheck(item.value));
                                        }}
                                        checked={field.value ?
                                            field.value.map((v) => v.code)
                                                .includes(item.value) : false}
                                    />
                                }
                                key={item.value}
                                label={intl.formatMessage({
                                    id: courseService.getLabelKeyByGroupAndCode(
                                        item.typeCode,
                                        item.value
                                    ),
                                })}
                            />
                        ))
                        }
                    </FormGroup>)
                }
                control={control}
            />
            <FormHelperText>
                {error ? intl.formatMessage({ id: error.message }) : null}
            </FormHelperText>
        </FormControl>
    );
};

export default RhfListOfValuesCheckboxes;