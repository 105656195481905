import React from "react";


import { injectIntl, navigate, useIntl } from "gatsby-plugin-intl";

import { Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Seo from "components/common/Seo";
import logService from "../../../services/api/logService";
import LayoutBo from "components/backoffice/common/layout/LayoutBo";


const EnrollmentList = ({ intl }) => {
    const columns = [
        {
            field: "code",
            headerName: intl.formatMessage({ id: "backoffice.batch.code" }),
            minWidth: 150,
            flex: 2,
            renderCell: (params) => {
                return params.value;
            }
        },
        {
            field: "state",
            headerName: intl.formatMessage({ id: "backoffice.batch.state" }),
            width: 200,
            renderCell: (params) => {
                return params.value;
            }
        },
        {
            field: "dateStart",
            headerName: intl.formatMessage({ id: "backoffice.batch.dateStart" }),
            width: 200,
            renderCell: (params) => {
                return params.value;
            }
        },
        {
            field: "dateEnd",
            headerName: intl.formatMessage({ id: "backoffice.batch.dateEnd" }),
            width: 200,
            renderCell: (params) => {
                return params.value;
            }
        }
    ];

    const [rows, setRows] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    const loadServerRows = React.useCallback(async () => {
        setLoading(true);
        let response = null;
        try {
            response = await logService.searchBatchKo(); 
            setRows(!!response.data ? response.data : []);   
        } catch (error) {
            if(error.data) console.error("Unexpected error occured : "+error.data);
            else console.error(error);
        }
        setLoading(false);
    }, []);



    function CustomNoResultOverlay() {
        const intl = useIntl();
        return (
            <div className="MuiDataGrid-overlay">
                <Typography align="center">
                    {intl.formatMessage({ id: "common.label.no.result" })}
                </Typography>
            </div>
        );
    }

    React.useEffect(() => {
        loadServerRows();
        setRows([]);
    }, [loadServerRows]);

    return (
        <LayoutBo>
            <Seo
                title={intl.formatMessage({ id: "backoffice.batch.title" })}
                lang={intl.locale}
            />
            <div style={{ height: 300, width: '100%' }}>
                <DataGrid
                    rows={rows}
                    autoHeight
                    columns={columns}
                    onRowDoubleClick={(e) => navigate(`/back-office/enrollments/${e.id}`)}
                    components={{
                        NoRowsOverlay: CustomNoResultOverlay
                    }}
                    getRowId={(row) => row.batchId}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                />
            </div>

        </LayoutBo>

    );
};

export default injectIntl(EnrollmentList);
