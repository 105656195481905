import React, {Fragment} from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { navigate, useIntl} from "gatsby-plugin-intl";


const styles = {
  marginLeft: {
      marginLeft: '2em'
  }
}

const ConfirmationDialog = ({ isCancel = false, isModified = false, listingUrl = null }) => {
  const intl = useIntl();
  const [open, setOpen] = React.useState(false);
  const labelIdButton = isCancel ? "back.to.cancel" : "back.to.listing";
  const labelIdDialogTitle = isCancel ? "back.to.cancel.confirmation.title" : "back.to.listing.confirmation.title";
  const labelIdDialogText = isCancel ? "back.to.cancel.confirmation.description" : "back.to.listing.confirmation.description";
  
  const goToUrl = () => {
    //workaround cause navigate don't work if page url is the current page  
    const urlToGo = "/"+intl.locale+listingUrl;
    if(location.pathname == urlToGo){
      window.location.pathname = urlToGo;
    } else {
      navigate(listingUrl);
    }
  }


  const goBack = () => {
    try {
      window.history.back();
    } catch (error) {
      console.error(error)
    }
  };
  
  const handleClickOpen = () => {
    if (isModified) {
      setOpen(true);
    } else {
        listingUrl ?  goToUrl() : goBack();
    }
  };

  const handleYes = (event) => {
    event.preventDefault();
    listingUrl ? goToUrl() : goBack();
  };

  const handleNo = () => {
    setOpen(false);
  };
  return (
    <Fragment>
      <Button
        variant="outlined"
        color="primary"
        style={styles.marginLeft}
        onClick={handleClickOpen}
      >
        {intl.formatMessage({ id: labelIdButton })} 
      </Button>
      <Dialog
        open={open}
        onClose={handleNo}
        aria-labelledby="alert-dialog-back-to-listing"
      >
        <DialogTitle id="alert-dialog-title">
          {intl.formatMessage({ id:  labelIdDialogTitle})}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {intl.formatMessage({
              id: labelIdDialogText,
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleYes} color="primary">
            {intl.formatMessage({
              id: "common.label.yes",
            })}
          </Button>
          <Button onClick={handleNo} color="primary" autoFocus>
            {intl.formatMessage({
              id: "common.label.no",
            })}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default ConfirmationDialog;


