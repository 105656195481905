import React from "react";
import { RhfTextField } from 'components/backoffice/common/RhfFields';
import { Grid, useMediaQuery, useTheme } from "@mui/material";


const AddEditInfosBloc = ({ id, useFormHook }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const defaultStyles = {
        textFieldLinkSx : { 
            justifyContent: "center", 
            width: isMobile ? '90%' : '93%'
        },
        textFieldLittleLinkSx : { 
            justifyContent: "center", 
            width: isMobile ? '90%' : '87%'
        }
    };

    return (    
    <Grid container id={id} rowSpacing={4}>  

        <Grid item xs={12} md={6}>
            <RhfTextField
                label="academic.link.publication.webpage"
                name="links.linkPublicationWebPage"
                required={false}
                sx={defaultStyles.textFieldLinkSx}
                control={useFormHook.control}>
            </RhfTextField>
        </Grid>
        <Grid item xs={12} md={6}>
            <RhfTextField
                label="academic.link.publication.feedlink"
                name="links.linkPublicationFeedLink"
                required={false}
                sx={defaultStyles.textFieldLinkSx}
                control={useFormHook.control}>
            </RhfTextField>
        </Grid>

       <Grid item xs={12} md={6}>
            <RhfTextField
                label="academic.link.pro.webpage"
                name="links.linkProWebPage"
                required={false}
                sx={defaultStyles.textFieldLinkSx}
                control={useFormHook.control}>
            </RhfTextField>  
        </Grid>
        <Grid item xs={12} md={6}>
            <RhfTextField
                label="academic.link.perso.webpage"
                name="links.linkPersoWebPage"
                required={false}
                sx={defaultStyles.textFieldLinkSx}
                control={useFormHook.control}>
            </RhfTextField>
        </Grid>

        <Grid item xs={12} md={6}>
            <RhfTextField
                label="academic.link.googleScholar"
                name="links.linkGoogleScholar"
                required={false}
                sx={defaultStyles.textFieldLinkSx}
                control={useFormHook.control}>
            </RhfTextField>
        </Grid>
        <Grid item xs={12} md={6}>
            <RhfTextField
                label="academic.link.researchGate.profile"
                name="links.linkResearchGateProfile"
                required={false}
                sx={defaultStyles.textFieldLinkSx}
                control={useFormHook.control}>
            </RhfTextField> 
        </Grid>

        <Grid item xs={12} md={3}>
            <RhfTextField
                label="academic.link.orcid"
                name="links.linkOrcid"
                required={false}
                sx={defaultStyles.textFieldLittleLinkSx}
                control={useFormHook.control}>
            </RhfTextField>
        </Grid> 
        <Grid item xs={12} md={3}>
            <RhfTextField
                label="academic.link.twitter"
                name="links.linkTwitter"
                required={false}
                sx={defaultStyles.textFieldLittleLinkSx}
                control={useFormHook.control}>
            </RhfTextField>  
        </Grid>
        <Grid item xs={12} md={6}>
            <RhfTextField
                label="academic.link.linkedIn"
                name="links.linkLinkedIn"
                required={false}
                sx={defaultStyles.textFieldLinkSx}
                control={useFormHook.control}>
            </RhfTextField>          
        </Grid>
            
    </Grid>       
    )
}
export default AddEditInfosBloc;
