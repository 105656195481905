import React, { useState } from "react";

import { FormHelperText, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';


import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {convertFromRaw, convertToRaw, EditorState} from "draft-js";
import "draft-js/dist/Draft.css";



import { useIntl } from "gatsby-plugin-intl";
import { OPPORTUNITY_TYPE } from "utils/constants/back-office-constants";


const useStyles = makeStyles((theme) => ({  
  toolbarClass: {
    backgroundColor: "#efefef!important",
  },
  editorClass: {
    border: "1px solid #ccc",
    minHeight: 200
  },
}));


const CustomEditor = ( { editorState, handleEditorStateChange, error } ) => {

  const useStyles = makeStyles((theme) => ({  
    toolbarClass: {
      backgroundColor: "#efefef!important",
    },
    editorClass: {
      border: "1px solid #ccc",
      minHeight: 200
    },
  }));

  const intl = useIntl();
  const classes = useStyles();

  const EditorReact = require("react-draft-wysiwyg").Editor;

  //const [editorDirty, setEditorDirty] = useState(false);
  //const [textEmpty, setTextEmpty] = useState(false);

  // const [editorState, setEditorState] = useState(
  //     EditorState.createEmpty()
  //     // editorStateTest
  // );


  // const onEditorStateChange = (editorState) => {
  //   if (editorState.getCurrentContent().hasText() || type === OPPORTUNITY_TYPE.alert) {
  //     setTextEmpty(false);
  //   }else{
  //     setTextEmpty(true);
  //   }
  //   setEditorState(editorState);
  //   setEditorDirty(true);
  // };

  //const editorStyle = ;
      
  return (<>    
    <Typography component={"span"} variant={"body2"}>                 
      <EditorReact                  
        editorState={editorState}          
        editorStyle={error ? { fontFamily: "Verdana", borderWidth: "1", borderColor: "#af4731", borderStyle: "solid" } 
                    : { fontFamily: "Verdana"}}
        onEditorStateChange={handleEditorStateChange}
        toolbar={{
          fontFamily: {
            options: [
              "Arial",
              "Georgia",
              "Impact",
              "Nunito Sans",
              "Tahoma",
              "Times New Roman",
              "Verdana",
            ],
          },
        }}
        editorClassName={classes.editorClass}
        toolbarClassName={classes.toolbarClass}
      />
      <FormHelperText sx={error ? { color: "#af4731" } : {}}>
          {error ? intl.formatMessage({ id: error.message }) : null}
      </FormHelperText> 
    </Typography>                                  
  </>);
}

export default CustomEditor;