import React, { Fragment, useEffect, useRef, useState } from 'react';

import { Alert, Box, Button, Card, CardContent, CardHeader, CircularProgress, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fade, Grid, IconButton, Modal, Snackbar, Tab, Tabs, Typography, useTheme } from '@mui/material';

import { useBeforeunload } from 'react-beforeunload';
import { FormProvider, useForm } from 'react-hook-form';
import { faArrowLeft, faArrowRight, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import { navigate, useIntl } from 'gatsby-plugin-intl';

import { getUserRole } from 'services/auth';
import courseService from 'services/api/courseService';
import { ROLE } from 'utils/constants/constants';
import { courseSchema, DISABLED_CONDITION, sessionSchema } from 'utils/courses-schema';

import Seo from 'components/common/Seo';
import ConfirmationDialog from 'components/common/ConfirmationDialog';
import LayoutBo from "components/backoffice/common/layout/LayoutBo";
import themeMuiV5 from "components/common/ThemeMuiV5";
import AddEditCourseForm from './components/AddEditCourseForm';
import AddEditCourseSessionForm from './components/AddEditCourseSessionForm';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: "100%",
    },
    textField: {
        marginRight: theme.spacing(8),
        marginTop: theme.spacing(1),
        width: "95%",
    },
    [theme.breakpoints.down('md')]: {
      textField: {
          marginRight: theme.spacing(6),
          marginTop: theme.spacing(1),
          width: "90%",
      },
    },
    textFieldLarge: {
        marginRight: theme.spacing(8),
        marginTop: theme.spacing(1),
        width: "100%",
    },
    [theme.breakpoints.down('lg')]: {
        textFieldLarge: {
            marginRight: theme.spacing(8),
            marginTop: theme.spacing(1),
            width: "90%",
        },
    },
    formControlLabel: {
        verticalAlign: "bottom",
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(8),
    },
    smallInput: {
        width: "8ch",
        marginRight: theme.spacing(4),
    },
    mediumInput: {
        width: "15ch",
        marginRight: theme.spacing(4),
    },
    marginLeft: {
        marginLeft: theme.spacing(3),
    },
    marginRight: {
        marginRight: theme.spacing(1),
    },
    card: {
        maxHeight: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    cardContent: {
        backgroundColor: theme.palette.background.paper,
    },
    cardHeader: {
        textAlign: "center",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
    draggable:{
        cursor:"move"
    }
}));

const styles = {
    marginLeft: {
        marginLeft: '2em'
    },
    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'transparent'
    }
}



const AddEditCoursePage = ({ courseId }) => {
    const intl = useIntl();
    const classes = useStyles();
    const fielsNameAndCondition = [
        {
            condition: "course.registrationDeadline",
            field: "deadlineRegistration",
            setDefaultValueNull: true,
        },
        { condition: "session.teachers", field: "session.teachers", setDefaultArrayValue: true },
        { condition: "course.subtype", field: "subtype" },
        { condition: "course.code", field: "code" },
        {
            condition: "course.engageCourse",
            field: "engageCourse",
            setDefaultValueBoolean: true,
        },
        { condition: "course.pkey", field: "key" },
        {
            condition: "course.openToRegistration",
            field: "openToRegistration",
            setDefaultValueBoolean: true,
        },
        { condition: "course.maxRegistration", field: "maxRegistration" },
        { condition: "course.period", field: "period" },
        { condition: "course.teacher", field: "teachers", setDefaultArrayValue: true },
        { condition: "course.academicEmail", field: "academicEmail" },
        { condition: "course.helpDeskEmail", field: "helpDeskEmail" },
    ];

    const role = getUserRole();
    const [tabPanelValue, setTabPanelValue] = useState(0);
    const [loading, setLoading] = useState(false);

    const [openDuplicateConfirmDialog, setOpenDuplicateConfirmDialog] = useState(false);
    const [openDuplicateInfoDialog, setOpenDuplicateInfoDialog] = useState(false);
    const [duplicate, setDuplicate] = useState(false);

    const defaultValues =  {            
        secondaryUniversities: [],
        university: "",            
        logo: "UNIVERSITY",
        type: "",
        subtype: "",
        code: "",
        period: "",
        engageCourse: false,
        courseLanguage: "",
        key: "",
        privateCourse: true,
        visible: false,
        leader: true,
        deadlineRegistration: null,
        openToRegistration: false,
        multiJoinCode: "",
        maxRegistration: "",
        title: "",
        shortDescription: "",
        longDescription: "",
        language: "",
        keywords: "",
        academicEmail: "",
        helpDeskEmail: "",
        cycles: [],
        links: [{ description: "", type: "", url: "" }],
        topics: [{ id: "" }],
        teachers: [
            {
                gender: "",
                firstname: "",
                lastname: "",
                email: "",
                title: "",
                university: "",
                remoteKey: "",
            },
        ],
        sessions: [
            {
                sessionId: "",
                key: "",
                format: "",
                language: "",
                title: "",
                startDate: null,
                endDate: null,
                links: [{ description: "", type: "", url: "" }],
                teachers: [
                    {
                        gender: "",
                        firstname: "",
                        lastname: "",
                        email: "",
                        title: "",
                        university: "",
                        remoteKey: "",
                    },
                ],
            },
        ],
    };

    const useCourseSchema = useRef(true);

    const methods = useForm({
        resolver: useCourseSchema.current
            ? yupResolver(courseSchema)
            : yupResolver(sessionSchema),
        defaultValues: defaultValues,
        mode: 'onChange'
    });

    const {
        trigger,
        reset,
        getValues,
        clearErrors,
        formState: { isDirty, errors },
    } = methods;

    const courseType = methods.watch("type");
    const [approved, setApproved] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(false);
            
    const MESSAGE_DURATION = { ACTION_SUCCESS: 10000, VALIDATION_ERROR: 7000, API_ERROR: 10000 };
    const MESSAGE_SEVERITY = { WARNING: 'warning', ERROR: 'error' };
    const [openError, setOpenError] = useState(false);
    const [severity, setSeverity] = useState("error");
    const [errorMessage, setErrorMessage] = useState("");
    const [errorParameters, setErrorParameters] = useState("");
    const [openSuccess, setOpenSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    const showErrorMessage = (errorMessage, errorDuration, severity, errorParameters) => {
        setErrorMessage(errorMessage);
        if(severity){
            setSeverity('warning');
        }        
        if(errorParameters){
            setErrorParameters(errorParameters);
        }   
        if(errorDuration) {
            setTimeout(() => {
                setOpenError(false);
            }, errorDuration);                           
        }
        setOpenError(true);
    }

    const showSuccessMessage = (successMessage) => {
        setSuccessMessage(successMessage);
        setTimeout(() => {
            setOpenSuccess(false);
        }, MESSAGE_DURATION.ACTION_SUCCESS);                                   
        setOpenSuccess(true);
    }

    const handleCloseDuplicateInfoDialog = () => {
        setDuplicate(false);
        setOpenDuplicateInfoDialog(false);
    }

    useEffect(() => {
        if (duplicate) {
            setOpenDuplicateInfoDialog(true);
        }
    });

    useEffect(() => {
        const handleRedirectionAfterCreate = () => {
            const urlParams = Object.fromEntries(new URLSearchParams(location?.search));
            if(urlParams['from']){
                const showSession = courseType && !DISABLED_CONDITION["session"].disabled(courseType);
                if(showSession){
                    useCourseSchema.current = false;
                    setTabPanelValue(1);
                }            
                showSuccessMessage("course.create.success", );
            }   
        }
        handleRedirectionAfterCreate();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await courseService.searchById(courseId);
                const data = result.data;
                setApproved(data.approved);
                if (!!data.secondaryUniversities) {
                    data['secondaryUniversities']=data.secondaryUniversities.map(
                        u => { return { 'label': u.universityName,'value': u.universityId.toString() } }
                    );
                }
                if (!!data.teachers) {
                    updateTeacherInitValues(data.teachers);
                }
                if (!!data.sessions) {
                    data.sessions.forEach((session) => {
                        if (!!!session.startDate) {
                            session.startDate = null;
                        }
                        if (!!!session.endDate) {
                            session.endDate = null;
                        }
                        if (!session.links || session.links.length === 0) {
                            session.links = [{ description: "", type: "", url: "" }];
                        }
                        if (!session.teachers || session.teachers.length === 0) {
                            session.teachers = [
                                {
                                    gender: "",
                                    firstname: "",
                                    lastname: "",
                                    email: "",
                                    title: "",
                                    university: "",
                                    remoteKye: "",
                                },
                            ];
                        } else {
                            updateTeacherInitValues(session.teachers);
                        }
                    });
                }
                const newDefaultValues = Object.assign({}, defaultValues, data);
                reset(newDefaultValues);
            } catch (error) {
                console.error(`error fetching course data of ${courseId} `, error);
                if (isPermissionError(error.status)) {
                    showErrorMessage("course.error.permission");
                } else {
                    navigate("/back-office/courses");
                }                
            }
        };

        const updateTeacherInitValues = (teachers) => {
            teachers.forEach((teacher) => {
                const currentTeacherKey = Object.keys(teacher);
                Object.keys(defaultValues.teachers[0]).forEach((key) => {
                    if (!currentTeacherKey.includes(key)) {
                        teacher[key] = "";
                    }
                });
            });
        };

        if (!!courseId) {
            fetchData();
        }
    }, [courseId]);

    useEffect(() => {
        const handleCourseTypeChange = async () => {
            clearErrors();
            const isValid = await trigger();
            if(!isValid){            
                showErrorMessage("error.course.create.validation", MESSAGE_DURATION.VALIDATION_ERROR, MESSAGE_SEVERITY.WARNING);
            } 
        }
        if(courseType){
            handleCourseTypeChange();      
        }        
    }, [courseType]);


    const validationSchema = { 0: true, 1: false };
    const handleChangeTabPanel = async (event, newValue) => {
        const result = await trigger();
        if (result) {
            courseSchema.current = validationSchema[newValue];
            setTabPanelValue(newValue);
        }
    };

    const goToNextStep = async () => {
        const result = await trigger();
        if (result) {
            useCourseSchema.current = false;
            setTabPanelValue(1);
        } else {
            showErrorMessage("error.course.create.validation", MESSAGE_DURATION.VALIDATION_ERROR, MESSAGE_SEVERITY.WARNING);
        }
    };

    useBeforeunload((event) => {
        if (isDirty) {
            event.preventDefault();
            event.returnValue = "";
        }
    });

    const resetDisabledField = () => {
        //prevent disabled fields from being sending on submit
        fielsNameAndCondition.forEach((item) => {
            if (DISABLED_CONDITION[item.condition].disabled(courseType)) {
                if (item.setDefaultValueNull) {
                    methods.setValue(item.field, null);
                } else if (item.setDefaultValueBoolean) {
                    methods.setValue(item.field, false);
                } else if (item.setDefaultArrayValue) {
                    methods.setValue(item.field, []);
                } else {
                    methods.setValue(item.field, "");
                }
            }
        });
    };

    const submitForm = async () => {
        let isValid = false;
        setLoading(true);
        setOpenSuccess(false);
        setOpenError(false);
        resetDisabledField();
        setDisableSubmit(true);
        const result = await trigger();
        if (result) {
            const formData = methods.getValues();
            if (!!courseId) {
                try {
                    await courseService.updateCourse(courseId, formData);
                    setLoading(false);
                    showSuccessMessage("course.update.success");
                    isValid = true;
                    methods.reset(methods.getValues());
                } catch (error) {
                    setLoading(false);
                    console.error('error updating course ', error);
                    handleError(error, 'update');
                }
            } else {
                try {
                    let result = await courseService.saveCourse(formData);
                    setLoading(false);
                    isValid = true;                    
                    const redirectUrl = '/back-office/courses/edit/'+result.data.id+'?from=saveCourse';
                    navigate(redirectUrl);
                } catch (error) {
                    setLoading(false);
                    console.error('error creating course ', error);
                    handleError(error, 'create');
                }
            }    
        } else {
            showErrorMessage("error.course.create.validation", MESSAGE_DURATION.VALIDATION_ERROR, MESSAGE_SEVERITY.WARNING);
        }
        setDisableSubmit(false);
        setLoading(false);
        return isValid;
    };

    const isPermissionError = (errorCode) => {
        let errorPermission = false
        if (401 == errorCode || 403 == errorCode) {
            errorPermission = true;
        }
        return errorPermission;
    }

    const handleError = (error, action) => {        
        let errorMessage;
        if (isPermissionError(error.status)) {
            errorMessage = "course.error.permission";
        } else {
            errorMessage = (error && error.data && error.data.errorCode
                ? error.data.errorCode
                : `course.${action}.error`
            );
        }
        showErrorMessage(errorMessage, null, error?.data?.parameters);
    }

    const handleApprove = () => {
        setDisableSubmit(true);
        if (approved) {
            courseService.disapproveCourse(courseId).then(() => {
                setApproved(false);
                showSuccessMessage("course.disapprove.success");
            });
        } else {
            courseService.approveCourse(courseId).then(() => {
                setApproved(true);
                showSuccessMessage("course.approve.success");
            });
        }
        setDisableSubmit(false);
    };   

    const DuplicateActionPanel = () => {
        const handleClickDuplicate = () => {
            setOpenDuplicateConfirmDialog(true);
        }
    
        const handleCloseDuplicateConfirmDialog = () => {
            setOpenDuplicateConfirmDialog(false);
        }
    
        const mapToDuplicatedCourse = (courseToDuplicate) => {
            let targetCourse = defaultValues; 
            Object.assign(targetCourse, courseToDuplicate);
            if (targetCourse) {
                targetCourse.approved = false;
                targetCourse.visible = false;
                if (targetCourse.key) {
                    targetCourse.key = targetCourse.key + "-" + getUID();
                }
                targetCourse.title = '[DUPLICATE] ' + targetCourse.title;
                if (targetCourse.links && targetCourse.links.length > 0) {
                    targetCourse.links = targetCourse.links.map(link => {
                        const courseLink = link;
                        courseLink.linkId = null;
                        return courseLink;
                    });
                }    
                if (targetCourse.sessions && targetCourse.sessions.length > 0) {
                    targetCourse.sessions = targetCourse.sessions.map(session => {
                        const mappedSession = session;
                        mappedSession.sessionId = null
                        if (session.key) {
                            mappedSession.key = session.key + "-" + getUID();
                        }
                        if (session.links && session.links.length > 0) {
                            mappedSession.links = session.links.map(link => {
                                const mappedLink = link;
                                mappedLink.linkId = null;
                                return mappedLink;
                            });
                        }
                        if (session.teachers && session.teachers.length > 0) {
                            mappedSession.teachers = session.teachers.map(teacher => {
                                const mappedTeacher = teacher;
                                mappedTeacher.teacherId = null;
                                return mappedTeacher;
                            });                            
                        } else {
                            mappedSession.teachers = null;
                        }
                        return mappedSession;
                    });
                }
            }
            return targetCourse;
        }
    
        const handleDuplicateCourse = async () => {
            setOpenDuplicateConfirmDialog(false);
            setLoading(true);
            setDisableSubmit(true);
            const response = await courseService.searchById(courseId);
            let duplicatedCourse = mapToDuplicatedCourse(response.data);
            courseService.saveCourse(duplicatedCourse)
                .then((response) => {
                    setLoading(false);
                    setDisableSubmit(false);
                    setOpenDuplicateConfirmDialog(false);
                    const duplicateCourseId = response.data.id;
                    setDuplicate(true);
                    navigate('/back-office/courses/edit/' + duplicateCourseId);                    
                })
                .catch((error) => {
                    setDisableSubmit(false);
                    setLoading(false);   
                    setOpenDuplicateConfirmDialog(false);                 
                    console.error('error duplicating course ', error);
                    handleError(error, 'duplicate');
                });
    
        };
        return (<>
            <Button
                id='duplicate-course-btn'
                variant="outlined"
                color="primary"
                style={styles.marginLeft}
                onClick={handleClickDuplicate}
                disabled={disableSubmit}
            >
                {intl.formatMessage({ id: "course.duplicate" })}
            </Button>
            <DuplicateConfirmDialog
                open={openDuplicateConfirmDialog}
                onDuplicate={handleDuplicateCourse}
                onClose={handleCloseDuplicateConfirmDialog}
                onCancel={handleCloseDuplicateConfirmDialog}
            /> 
        </>);
    }

    const CourseButtonPanel = () => {
    return (
    <Grid py={2} container justifyContent="center">
        <Box py={3}>
            {!DISABLED_CONDITION["session"].disabled(courseType) ? 
                (
                    <>
                        <Button
                            id='course-to-session-btn'
                            variant="contained"
                            color="primary"
                            style={styles.marginLeft}
                            onClick={goToNextStep}
                        >
                            {intl.formatMessage({ id: "course.to.session" })}
                            <FontAwesomeIcon
                                className={classes.marginLeft}
                                icon={faArrowRight}
                            ></FontAwesomeIcon>
                        </Button>

                        <ConfirmationDialog
                            isModified={isDirty}
                            listingUrl="/back-office/courses"
                        />

                        <ApproveBtn 
                            role={role} 
                            courseId={courseId} 
                            handleApprove={handleApprove} 
                            disableSubmit={disableSubmit} 
                            approved={approved} />

                        { courseId && <DuplicateActionPanel /> }
                        
                    </>
                ) : (
                    <>
                        <CourseSubmitSection
                            isModified={isDirty}
                            submitForm={submitForm}
                            disabled={disableSubmit}                                                        
                        />
                        <ApproveBtn
                            role={role}
                            courseId={courseId}
                            handleApprove={handleApprove}
                            disableSubmit={disableSubmit}
                            approved={approved}
                        />
                    </>
                )}
        </Box>
    </Grid>
    )}

    const SessionButtonPanel = () => {
        return (
        <Grid container justifyContent="center">
            <Button
                id='course-to-course-btn'
                variant="outlined"
                color="primary"
                style={styles.marginLeft}
                onClick={() => {
                    useCourseSchema.current = true;
                    setTabPanelValue(0);
                }}
            >
                <FontAwesomeIcon
                    className={classes.marginRight}
                    icon={faArrowLeft}
                ></FontAwesomeIcon>
                {intl.formatMessage({ id: "course.to.course" })}
            </Button>
            <CourseSubmitSection
                isModified={isDirty}
                submitForm={submitForm}
                disabled={disableSubmit}
            />
        </Grid>
        )
    }

    return (
        <LayoutBo>
            <Seo title={
                    !!courseId
                        ? intl.formatMessage({ id: "course.edit" })
                        : intl.formatMessage({ id: "course.new" })
                }
                lang={intl.locale}
            />

            <DuplicateInfoDialog
                open={openDuplicateInfoDialog}
                onClose={handleCloseDuplicateInfoDialog}
                onOk={handleCloseDuplicateInfoDialog}
                dialogTitle={intl.formatMessage({ id: "course.duplicateInfoDialog.title" })}
                dialogContentText={intl.formatMessage({ id: "course.duplicateInfoDialog.text" })}
            />

            <Card className={classes.card}>
                <CardHeader component="h1" styles={styles.cardHeader}
                    title={!!courseId
                        ? intl.formatMessage({ id: "course.edit" })
                        : intl.formatMessage({ id: "course.new" })}
                />
                
                <Modal
                    open={loading}
                    aria-labelledby="Loading modal"
                    aria-describedby="Waiting for saving session"
                    >
                    <Box sx={styles.modal}>
                        <CircularProgress />
                    </Box>
                </Modal>
                
                <CardContent className={classes.cardContent}>
        
                    {/* {errors && <p>Errors: {JSON.stringify(errors)}</p>}
                     {getValues() && <p>Values: {JSON.stringify(getValues('topics'))}</p>} */}

                    <div className={classes.root}>
                        <Tabs
                            value={tabPanelValue}
                            onChange={handleChangeTabPanel}
                            indicatorColor="primary"
                            textColor="primary"
                            aria-label="full width tabs example"
                        >
                            <Tab label="Course" {...a11yProps(0)} />
                            {!DISABLED_CONDITION["session"].disabled(courseType) && (
                                <Tab label="Session" {...a11yProps(1)} />
                            )}
                        </Tabs>

                        <FormProvider {...methods}>
                            <form noValidate>
                                <TabPanel index={0} value={tabPanelValue} dir={themeMuiV5.direction}>                                                                        
                                    <AddEditCourseForm className={classes} control={methods.control} />
                                    <CourseButtonPanel />
                                </TabPanel>
                                
                                {!DISABLED_CONDITION["session"].disabled(courseType) && (
                                    <TabPanel index={1}  value={tabPanelValue} dir={themeMuiV5.direction}>
                                        <AddEditCourseSessionForm submitForm={submitForm} classes={classes} control={methods.control} />
                                        <SessionButtonPanel />
                                    </TabPanel>
                                )}
                            </form>
                        </FormProvider>
                    </div>
                </CardContent>
            </Card>
            <SuccessAlert 
                intl={intl} 
                successMessage={successMessage} 
                openSuccess={openSuccess} 
                setOpenSuccess={setOpenSuccess} 
            />
            <ErrorAlert 
                intl={intl}
                severity={severity}
                errorMessage={errorMessage}
                errorParameters={errorParameters}    
                openError={openError} 
                setOpenError={setOpenError}                          
            />            
        </LayoutBo>
    );
}
    ;

export default AddEditCoursePage;


const SuccessAlert = ({intl, openSuccess, setOpenSuccess, successMessage}) => {
    return (
        <>
        { openSuccess && (
            <Collapse in={openSuccess}>
                <Alert
                    severity="success"
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setOpenSuccess(false);
                            }}
                        >
                            <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
                        </IconButton>
                    }
                >
                    {intl.formatMessage({ id: successMessage })}
                </Alert>
            </Collapse>
        )}
        </>
    )
}


const ErrorAlert = ({intl, severity, openError, setOpenError, errorMessage, errorParameters}) => {
    return (<>
        { openError && (            
            <Collapse in={openError}>
                <Alert
                    severity={severity}
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setOpenError(false);
                            }}
                        >
                            <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
                        </IconButton>
                    }
                >
                    <Typography variant="body1" paragraph>
                        {intl.formatMessage({ id: errorMessage })} {errorParameters}
                    </Typography>
                </Alert>
            </Collapse>
        )}
    </>)
}

const DuplicateConfirmDialog = (props) => {
    const { open, onClose, onCancel, onDuplicate } = props;
    const intl = useIntl();
    return (
        <div>
            <Dialog
                maxWidth={"sm"}
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {intl.formatMessage({ id: "course.duplicateConfirmDialog.title" })}
                </DialogTitle>

                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {intl.formatMessage({ id: "course.duplicateConfirmDialog.text" })}
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button onClick={onCancel} autoFocus>{intl.formatMessage({ id: "course.duplicateConfirmDialog.cancel" })}</Button>
                    <Button onClick={onDuplicate}>{intl.formatMessage({ id: "course.duplicateConfirmDialog.duplicate" })}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const DuplicateInfoDialog = (props) => {
    const { open, dialogTitle, dialogContentText, onClose, onOk } = props;
    const intl = useIntl();
    return (
        <div>
            <Dialog
                maxWidth={"sm"}
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    style: {
                        whiteSpace: 'pre-wrap',
                    },
                }}
            >
                <DialogTitle id="alert-dialog-title">
                    {dialogTitle}
                </DialogTitle>

                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {dialogContentText}
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button onClick={onOk} autoFocus> {intl.formatMessage({ id: "course.duplicateInfoDialog.ok" })} </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}>
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    );
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        "aria-controls": `full-width-tabpanel-${index}`,
    };
}

const getUID = () => {
    return Date.now().toString(36);
}

const CourseSubmitSection = ({
    isModified,
    submitForm,
    disabled
}) => {
    const intl = useIntl();
    return (
        <Fragment>
            <Button
                id='save-course-btn'
                variant="contained"
                color="primary"
                style={styles.marginLeft}
                onClick={submitForm}
                disabled={disabled}
            >
                {intl.formatMessage({ id: "course.save" })}
            </Button>

            <ConfirmationDialog
                isModified={isModified}
                listingUrl="/back-office/courses"
            />
        </Fragment>
    );
};

const ApproveBtn = ({ role, courseId, handleApprove, disableSubmit, approved }) => {
    const intl = useIntl();
    let isVisible = (!!role && role.some((r) => [ROLE.admin, ROLE.admin_university, ROLE.courses_approver].includes(r)) && !!courseId) ? true : false;

    return isVisible ? (
        <Button
            id='approve-disapprove-course-btn'
            variant="outlined"
            color="primary"
            onClick={handleApprove}
            style={styles.marginLeft}
            disabled={disableSubmit}
        >
            {approved
                ? intl.formatMessage({ id: "course.disapprove" })
                : intl.formatMessage({ id: "course.approve" })}
        </Button>
    )
        : null;
};