import * as React from 'react';
import { useEffect, useState } from 'react';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { IconButton, TextField, Tooltip } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import ReplayIcon from '@mui/icons-material/Replay';

import CvcCheckboxListVirtualized from 'ui/CvcCheckboxListVirtualized';
import compareByLabel from 'ui/CvcListUtils';



const CvcTransferList = ({ 
    showActions = defaultShowActions,     
    items = [], selectedItems = [], onChange, 
    filterLabel = 'Filter Term',
    showReloadData = false, onReloadData=null,
    handleSort = compareByLabel, 
    width='100%', height='100%', listHeight=440
}) => {

    const [filterTerm, setFilterTerm] = useState('');
    const [checked, setChecked] = useState([]);
    const [right, setRight] = useState(selectedItems);
    const [left, setLeft] = useState(items);
    const [filteredLeft, setFilteredLeft] = useState(items);

    const leftChecked = intersection(checked, filteredLeft);
    const rightChecked = intersection(checked, right);

    const handleDeleteFilterTerm = () => {
        setFilterTerm('');
        setFilteredLeft(left);
    }

    useEffect(()=> {
        const debouncerTime = setTimeout(()=> {
            filterLeftItems(filterTerm);
        }, 200);
        return () => { clearTimeout(debouncerTime); }
    }, [filterTerm]);

    const handleFilterTermChange = (e) => {
        setFilterTerm(e.target.value);                
    }

    const filterLeftItems = (filterTerm) => {
        if(filterTerm != ''){
            const filteredData = left.filter(item => item?.label?.toLowerCase().includes(filterTerm.toLowerCase()));
            setFilteredLeft(filteredData);        
        } else {
            setFilteredLeft(left);
        }          
    }

    const handleCheckItems = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    };

    const handleAllRight = () => {
        setRight(right.concat(filteredLeft).sort(handleSort));
        setFilteredLeft([]);
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked).sort(handleSort));
        setFilteredLeft(not(filteredLeft, leftChecked).sort(handleSort));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setFilteredLeft(filteredLeft.concat(rightChecked).sort(handleSort));
        setRight(not(right, rightChecked).sort(handleSort));
        setChecked(not(checked, rightChecked));
    };

    const handleAllLeft = () => {
        setFilteredLeft(filteredLeft.concat(right).sort(handleSort));
        setRight([]);
    };

    useEffect(() => {
        onChange(right);
    }, [right]);

    const ButtonsPanel = ({ id }) => {    
    return (
        <Grid container id={id} direction="column" sx={{ alignItems: 'center', width: '70px' }}>                        
            {showActions.showMoveAllRight &&
                <Button
                    sx={{ my: 0.5 }}
                    variant="outlined"
                    size="small"
                    onClick={handleAllRight}
                    disabled={filteredLeft.length === 0}
                    aria-label="move all right"
                >
                    ≫
                </Button>
            }
            {showActions.showMoveSelectedRight &&
                <Button
                    sx={{ my: 0.5 }}
                    variant="outlined"
                    size="small"
                    onClick={handleCheckedRight}
                    disabled={leftChecked.length === 0}
                    aria-label="move selected right"
                >
                    &gt;
                </Button>
            }
            {showActions.showMoveSelectedLeft &&
                <Button
                    sx={{ my: 0.5 }}
                    variant="outlined"
                    size="small"
                    onClick={handleCheckedLeft}
                    disabled={rightChecked.length === 0}
                    aria-label="move selected left"
                >
                    &lt;
                </Button>
            }
            {showActions.showMoveAllLeft &&
                <Button
                    sx={{ my: 0.5 }}
                    variant="outlined"
                    size="small"
                    onClick={handleAllLeft}
                    disabled={right.length === 0}
                    aria-label="move all left"
                >
                    ≪
                </Button>
            }
        </Grid>
        );
    };    

    const dynamicListWidth = 'calc((100% - 102px)/2)';
    return (
        <div style={{ width: width, height: height }}>
            <FilterField
                label={filterLabel} 
                filterTerm={filterTerm} 
                onFilterTermChange={handleFilterTermChange} 
                onDeleteFilterTerm={handleDeleteFilterTerm} 
                width={dynamicListWidth}
                showReloadData={showReloadData}
                onReloadData={onReloadData}
            />
                
            <Grid container spacing={2} sx={{ alignItems: 'center', width: '100%' }}>                
                <Grid item id="transfer-list-left" width={dynamicListWidth}>
                    <CvcCheckboxListVirtualized
                        items={filteredLeft} 
                        checkedItems={checked} 
                        onCheckItems={handleCheckItems}
                        width={'100%'}
                        height={height}
                        listHeight={listHeight}
                    />
                </Grid>
                <Grid item id="transfer-list-buttons">
                    <ButtonsPanel id="transfer-list-buttons" />
                </Grid>
                <Grid item id="transfer-list-right" width={dynamicListWidth}>
                    <CvcCheckboxListVirtualized
                        items={right} 
                        checkedItems={checked} 
                        onCheckItems={handleCheckItems}
                        width={'100%'}
                        height={height}
                        listHeight={listHeight}
                    />
                </Grid>
            </Grid>
        </div>            
    );
}

const not = (a, b) => {
    return a.filter((value) => !b.includes(value));
}

const intersection = (a, b) => {
    return a.filter((value) => b.includes(value));
}

const defaultShowActions = {
    showReloadData: true,
    showMoveAllRight: true,
    showMoveAllLeft: true,
    showMoveSelectedRight: true,
    showMoveSelectedLeft: true
}

const FilterField = ({ label, filterTerm, onFilterTermChange, onDeleteFilterTerm, width, showReloadData=false,
    onReloadData=null }) => {
    return (
    <Grid container width={width} sx={{ marginTop: '10px'}}>
        {/* <div style={{ display: 'flex', alignContent: 'center', alignItems: 'flex-end', marginTop: '10px', marginBottom: '20px', width: width }}> */}
            { (showReloadData && onReloadData) && 
                <Grid item xs={3} alignContent={'center'} textAlign={'start'}>               
                    <Tooltip title='Reload data'>        
                        <IconButton
                            aria-label="reload data"
                            color="primary"
                            size="small"
                            onClick={onReloadData}                        
                            sx={{ width: '24px', height: '24px', margin: 0 }}
                        >
                            <ReplayIcon sx={{ color: 'action.active', ml: 1, my: 0 }} />
                        </IconButton>
                    </Tooltip>
                </Grid>    
            }
        <Grid item xs={9} sx={{ display: 'flex', alignItems: 'center'}}>        
            <SearchIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
            <TextField 
                id="filter-term"
                label={label}
                sx={{ width: '70%', marginBottom: '15px' }}
                value={filterTerm}
                size='small'
                variant='standard'
                onChange={onFilterTermChange}
            />
            <Tooltip title='Delete filter term'>                    
                <IconButton
                    aria-label="deleteFilterTerm"
                    color="primary"
                    size="small"
                    onClick={onDeleteFilterTerm}
                    sx={{ width: '24px', height: '24px', margin: 0 }}
                >
                    <ClearIcon sx={{ color: 'action.active', ml: 1, my: 0 }} />
                </IconButton> 
            </Tooltip> 
        </Grid>
        {/* </div>  */}
    </Grid>
    );
}


export default CvcTransferList;
