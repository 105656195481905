import React from 'react';

import * as contentStyles from 'styles/backoffice/content.module.scss';
import Container from '@mui/material/Container';


const ContentBo = ({children,inverseBackground}) => {
    return (
        <main>
            <Container className ={`${contentStyles.main} ${!!inverseBackground? contentStyles.greyBackground: contentStyles.whiteBackground}`}>
                {children}
            </Container>
        </main>
    )
}

export default ContentBo