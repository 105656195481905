import React from "react";
import {GROUP} from "utils/constants/back-office-constants";
import RhfAutoCompleteListOfValuesMultiChoice from "ui/RhfAutoCompleteListOfValuesMultiChoice";
import { Grid } from "@mui/material";

const defaultStyles = {
    listOfValuesClass: {
        justifyContent: "center",
        width: "95%",
    },
}; 

const AddEditMultiValuesBloc = ({ id, useFormHook }) => {

return (
<Grid container id={id} rowSpacing={4} style={{ marginTop:'10px', marginBottom:'40px' }}>  

    <Grid item xs={12} md={4}>  
        <RhfAutoCompleteListOfValuesMultiChoice
            id="create-academic-academicTitles"
            name={"academicTitles"}
            addOption={true}
            inputLabel={"academic.academicTitles"}
            group={GROUP.academicTitle}
            control={useFormHook.control}
            setValue={useFormHook.setValue}
            getValues={useFormHook.getValues}
            sxInput={defaultStyles.listOfValuesClass}
            sxAutoComplete={defaultStyles.listOfValuesClass}                          
        />
    </Grid> 
    <Grid item xs={12} md={4}>  
        <RhfAutoCompleteListOfValuesMultiChoice
            id="create-academic-academicDisciplines"
            name={"academicDisciplines"}
            addOption={true}
            inputLabel={"academic.academicDisciplines"}
            group={GROUP.academicDiscipline}
            control={useFormHook.control}
            setValue={useFormHook.setValue}
            getValues={useFormHook.getValues}
            sxInput={defaultStyles.listOfValuesClass}
            sxAutoComplete={defaultStyles.listOfValuesClass}         
        />
    </Grid>
    <Grid item xs={12} md={4}>  
        <RhfAutoCompleteListOfValuesMultiChoice
            id="create-academic-expertiseFields"
            name={"expertiseFields"}
            addOption={true}
            inputLabel={"academic.expertiseFields"}
            group={GROUP.expertiseField}
            control={useFormHook.control}
            setValue={useFormHook.setValue}
            getValues={useFormHook.getValues}
            sxInput={defaultStyles.listOfValuesClass}
            sxAutoComplete={defaultStyles.listOfValuesClass}                      
        />
    </Grid>   
            
            
    <Grid item xs={12} md={4}>  
        <RhfAutoCompleteListOfValuesMultiChoice
            id="create-academic-workingLanguages"                
            name={"workingLanguages"}
            addOption={true}
            inputLabel={"academic.workingLanguages"}
            group={GROUP.workingLanguage}
            control={useFormHook.control}
            setValue={useFormHook.setValue}
            getValues={useFormHook.getValues}
            sxInput={defaultStyles.listOfValuesClass}
            sxAutoComplete={defaultStyles.listOfValuesClass}       
        />
    </Grid>
    <Grid item xs={12} md={4}>  
        <RhfAutoCompleteListOfValuesMultiChoice
            id="create-academic-researchCountries"
            name={"researchCountries"}
            addOption={true}            
            inputLabel={"academic.researchCountries"}
            group={GROUP.researchCountry}
            control={useFormHook.control}
            setValue={useFormHook.setValue}
            getValues={useFormHook.getValues}
            sxInput={defaultStyles.listOfValuesClass}
            sxAutoComplete={defaultStyles.listOfValuesClass}       
        />
    </Grid>
    <Grid item xs={12} md={4}>  
        <RhfAutoCompleteListOfValuesMultiChoice
            id="create-academic-researchProjects"
            name={"researchProjects"}
            addOption={true}                
            inputLabel={"academic.researchProjects"}
            group={GROUP.researchProject}
            control={useFormHook.control}
            setValue={useFormHook.setValue}
            getValues={useFormHook.getValues}
            sxInput={defaultStyles.listOfValuesClass}
            sxAutoComplete={defaultStyles.listOfValuesClass}                       
        />
    </Grid>            
            
</Grid>
)}

export default AddEditMultiValuesBloc;
