import * as React from 'react';
import { Autocomplete, Box, TextField }  from '@mui/material';
import { Controller } from "react-hook-form";
import { useIntl } from "gatsby-plugin-intl";

const RhfAutoCompleteMonoValue = ({
      name,
      options,
      label,
      control,
      setValue,
      defaultValue = "",
      className = null,
      ...props
}) => {
  const intl = useIntl();
  const [selectedValue, setSelectedValue] = React.useState("");
  React.useEffect(() => {
    setValue(name, selectedValue);
  }, [selectedValue]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Autocomplete
          {...props}
          disableListWrap
          options={options}
          getOptionLabel={(option) => option?.label} 
          renderOption={(props, option, index) => {
            return (
              <li {...props} key={option.value}>   
                {option.label}
              </li>
            );
          }}     
          renderInput={(params) => (
            <TextField {...params}
                label={intl.formatMessage({ id: label })}
                placeholder={intl.formatMessage({ id: label })}
                variant="standard"
                className={className}
            />
          )}
          isOptionEqualToValue={(option, value) => { return option?.value === value?.value }}
          onChange={(event, option) => {
            setSelectedValue(option?.value);
          }}
        />
      )}
    />
  );
}

export default RhfAutoCompleteMonoValue;