import React from "react";

import { Button } from "@mui/material";
import Grid from "@mui/material/Grid";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFieldArray } from "react-hook-form";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useIntl } from "gatsby-plugin-intl";

import { RhfListOfValuesSelect, RhfTextField } from "../../../common/RhfFields";
import courseService from "services/api/courseService";;
import { GROUP } from "utils/constants/back-office-constants";



const styles = {
    draggable: {
        cursor: "move"
    }
}

const MultiTeacherSelect = ({
    control,
    name,
    disabled,
    register,
    errors,
    getValues,
    setValue
}) => {
    const intl = useIntl();
    const updateTeacherInfoByEmail = async (index) => {
        if (getValues(`${name}.${index}.email`)) {
            await courseService.getTeacherByCriteria({ email: getValues(`${name}.${index}.email`) }).then(response => {
                const data = response.data;
                if (data && data.teacherId !== getValues(`${name}.${index}.teacherId`)) {
                    setValue(`${name}.${index}.title`, data.title ? data.title : '');
                    setValue(`${name}.${index}.firstname`, data.firstname);
                    setValue(`${name}.${index}.lastname`, data.lastname);
                    setValue(`${name}.${index}.university`, data.university ? data.university : '');
                    setValue(`${name}.${index}.remoteKey`, data.remoteKey ? data.remoteKey : '');
                    setValue(`${name}.${index}.teacherId`, data.teacherId);
                } else {
                    setValue(`${name}.${index}.teacherId`, '');

                }
            }).catch(error => {
            });
        }
    }

    const updateTeacherInfoByUniversityAndRemoteKey = async (index) => {
        if (getValues(`${name}.${index}.remoteKey`) && getValues(`${name}.${index}.university`)) {

            await courseService.getTeacherByCriteria({
                remoteKey: getValues(`${name}.${index}.remoteKey`),
                universityId: getValues(`${name}.${index}.university`)
            }).then(response => {
                const data = response.data
                if (data && data.teacherId !== getValues(`${name}[${index}].teacherId`)) {
                    setValue(`${name}.${index}.title`, data.title);
                    setValue(`${name}.${index}.firstname`, data.firstname);
                    setValue(`${name}.${index}.lastname`, data.lastname);
                    setValue(`${name}.${index}.email`, data.email);
                    setValue(`${name}.${index}.teacherId`, data.teacherId);
                } else if (!!!getValues(`${name}[${index}].email`)) {
                    setValue(`${name}.${index}.teacherId`, '');
                }
            }).catch(error => {
            });
        }
    }

    const { fields, append, remove, move } = useFieldArray({ name: name, control });

    const onDrag = (e, i) => {
        e.dataTransfer.setData("index", i);
    }

    const onDrop = (e, i) => {
        const originIndex = e.dataTransfer.getData("index");
        move(originIndex, i);
    }

    const onDragOver = (e) => {
        e.preventDefault();
    }

    return fields.map((teacher, i) => {
        return (

            <Grid container spacing={2} key={teacher.id} style={styles.draggable}
                draggable onDragStart={(e) => onDrag(e, i)}
                onDrop={(e) => {
                    onDrop(e, i)
                }}
                onDragOver={(e) => onDragOver(e)}
            >
                <input type="hidden" {...register(`${name}.${i}.teacherId`)}></input>
                {/* <RhfListOfValuesSelect
name={name + `.${i}.gender`}
defaultValue={teacher.gender}
label={"teacher.gender"}
group={GROUP.gender}
control={control}
className={`${classes.textField} ${classes.smallInput}`}
id={`${name}-gender-${i}-select`}
disabled={disabled}
/> */}
                <Grid item xl={1} md={1} sm={12} xs={12}>
                    <RhfTextField
                        label="teacher.remoteKey"
                        name={name + `.${i}.remoteKey`}
                        defaultValue={teacher.remoteKey}
                        fullWidth
                        control={control}
                        id={`${name}-remoteKey-${i}-text`}
                        disabled={disabled}
                        onBlurField={updateTeacherInfoByUniversityAndRemoteKey}
                        index={i}
                    />
                </Grid>
                <Grid item xl={2} md={2} sm={12} xs={12}>
                    <RhfListOfValuesSelect
                        name={name + `.${i}.university`}
                        defaultValue={teacher.university}
                        label={"course.university"}
                        group={GROUP.university}
                        control={control}
                        fullWidth
                        id={`${name}-university-${i}-select`}
                        disabled={disabled}
                        onBlurField={updateTeacherInfoByUniversityAndRemoteKey}
                        error={errors && errors[i] ? errors[i].university : null}
                        InputProps={{
                            readOnly: getValues(`${name}.${i}.teacherId`) !== "" && getValues(`${name}.${i}.university`) !== ""
                        }}
                        index={i}
                    />
                </Grid>
                <Grid item xl={2} md={2} sm={12} xs={12}>
                    <RhfTextField
                        label="teacher.email"
                        name={name + `.${i}.email`}
                        defaultValue={teacher.email}
                        fullWidth
                        control={control}
                        id={`${name}-email-${i}-text`}
                        disabled={disabled}
                        onBlurField={updateTeacherInfoByEmail}
                        error={errors && errors[i] ? errors[i].email : null}
                        index={i}
                    />
                </Grid>
                <Grid item xl={2} md={2} sm={12} xs={12}>
                    <RhfTextField
                        label="teacher.firstname"
                        name={name + `.${i}.firstname`}
                        defaultValue={teacher.firstname}
                        fullWidth
                        control={control}
                        id={`${name}-firstname-${i}-text`}
                        disabled={disabled}
                        error={errors && errors[i] ? errors[i].firstname : null}
                        InputProps={{
                            readOnly: !!getValues(`${name}[${i}].teacherId`),
                        }}
                    />
                </Grid>
                <Grid item xl={2} md={2} sm={12} xs={12}>
                    <RhfTextField
                        label="teacher.lastname"
                        name={name + `.${i}.lastname`}
                        defaultValue={teacher.lastname}
                        fullWidth
                        control={control}
                        id={`${name}-lastname-${i}-text`}
                        disabled={disabled}
                        error={errors && errors[i] ? errors[i].lastname : null}
                        InputProps={{
                            readOnly: !!getValues(`${name}[${i}].teacherId`),
                        }}
                    />
                </Grid>
                <Grid item xl={2} md={2} sm={11} xs={11}>
                    <RhfListOfValuesSelect
                        name={name + `.${i}.title`}
                        defaultValue={teacher.title}
                        label={"teacher.title"}
                        group={GROUP.title}
                        control={control}
                        fullWidth
                        id={`${name}-title-${i}-select`}
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xl={1} md={1} sm={1} xs={1}>
                    <Grid
                        container
                        direction="column"
                        alignItems="center"
                    >
                        {!disabled && fields.length !== 1 && (
                            <Grid item>
                                <Button
                                    onClick={() => remove(i)}
                                    title={intl.formatMessage({ id: "teacher.delete" })}
                                    color="secondary"
                                >
                                    <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
                                </Button>
                            </Grid>
                        )}
                        {!disabled && fields.length - 1 === i && (
                            <Grid item>
                                <Button
                                    onClick={() =>
                                        append({
                                            gender: "",
                                            firstname: "",
                                            lastname: "",
                                            email: "",
                                            title: "",
                                            university: "",
                                            remoteKey: "",

                                        })
                                    }
                                    title={intl.formatMessage({ id: "teacher.add" })}
                                    color="primary"
                                >
                                    <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        );
    });
};


export default MultiTeacherSelect;