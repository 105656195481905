import { WSInstance } from "./axios_config";

const findReferenceDataByTable = async (referenceTableCode) => {
  const config = { withCredentials: true };
  let formData = new FormData();
  formData.append("referenceTableCode", referenceTableCode);
  return await WSInstance.post(`${process.env.API_URL}/repositories`, formData, config);
};

const findReferenceDataNotUsedByTable = async (referenceTableCode) => {
  const config = { withCredentials: true };
  let formData = new FormData();
  formData.append("referenceTableCode", referenceTableCode);
  return await WSInstance.post(`${process.env.API_URL}/repositories/not-used`, formData, config);
};

const standardizeReferenceData = async (standardizeReferenceDataDto) => {  
  const config = { withCredentials: true };
  return await WSInstance.post(`${process.env.API_URL}/repositories/standardize`, standardizeReferenceDataDto, config);
};

const addReferenceData = async (addReferenceDataDto) => {
  const config = { withCredentials: true };
  return await WSInstance.post(`${process.env.API_URL}/repositories/add`, addReferenceDataDto, config);
};

const deleteReferenceData = async (deleteReferenceDataDto) => {
  const config = { withCredentials: true };
  return WSInstance.post(`${process.env.API_URL}/repositories/delete`, deleteReferenceDataDto, config);
};

const repositoryService = {
  findReferenceDataByTable, 
  findReferenceDataNotUsedByTable,
  standardizeReferenceData, 
  addReferenceData, 
  deleteReferenceData
};

export default repositoryService;
