
const convertToCSV = async (objArray) => {
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let fileStr = '\ufeff'; 
    //BOM for accent : https://stackoverflow.com/questions/155097/microsoft-excel-mangles-diacritics-in-csv-files

    const keys = typeof array[0] == 'object' ? Object.keys(array[0]) : [];
    let headers = keys.toString().replace(/,/g, ";");
    fileStr += headers + '\r';
    
    for (let idLine = 0; idLine < array.length; idLine++) {
      let line = '';
      for (let idColumn in array[idLine]) {
        if (line !== '') line += ';';
        line += convertToString(array[idLine][idColumn]);
      }
      fileStr += line + '\r';
    }
    return fileStr;
  };

  const convertToString = (property) => {
    if(property instanceof Array){
      const labelArray =  property.map(object => { return object.label });
      return labelArray.toString();
    } else {
      return property;
    }
  }

  const downloadCSV = async (data, fileName) => {
    const csvData = new Blob([data], { type: 'text/csv' });
    const csvURL = URL.createObjectURL(csvData);
    const link = document.createElement('a');
    link.href = csvURL;
    link.download = `${fileName}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  export { 
    downloadCSV, 
    convertToCSV 
  };