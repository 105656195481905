import React from "react";

const CvcTabPanel = (props) => {
    const { children, id, value, index, ariaLabel, ...other } = props;
    return (
        <div id={id} hidden={value !== index} aria-labelledby={ariaLabel} role="tabpanel" 
            style={{ height: '100%' }} {...other}
        >
            {value === index && (
                <>{children}</>
            )}
        </div>
    );
}

export default CvcTabPanel;