import React, { useEffect, useState } from "react";
import ResultSnackbars from "components/backoffice/common/ResultSnackbars";
import { useIntl } from "gatsby-plugin-intl";

import { Button, CircularProgress, Grid, IconButton, ListItem, ListItemAvatar, ListItemText, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import { styled } from '@mui/material/styles';
import { Visibility, CloudUpload, FileDownload } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';

import { capitalizeFirstLetter } from "utils/string-utils";
import ConfirmationDialog from "components/common/ConfirmationDialog";
import CvcConfirmDeleteDialog from "ui/CvcConfirmDeleteDialog";

import userService from "services/api/userService";
import {getUser} from 'services/api/requests';
import CvcViewPdfDialog from "ui/CvcViewPdfDialog";


const getDocNameLabel = (document) => { 
    return document.documentName ? document.documentName : "No fileName"; 
}
const getDocDepositDateLabel = (document, locale) => { 
    let docDepositDate = "No deposit Date";
    if(document.documentDepositDate){
        const date = new Date(document.documentDepositDate).toLocaleDateString(locale);
        const time = new Date(document.documentDepositDate).toLocaleTimeString(locale);
        docDepositDate = `${date}  -  ${time}`;
    } 
    return docDepositDate;
}
const getDocSizeLabel = (document) => { 
    return document.documentSize ? `${Math.round(document.documentSize/1024)} Ko` : "No size"; 
} 
const getDocDepositUserLabel = (document, intl) => { 
    return document.userModifierEmail ? `${intl.formatMessage({id: "backoffice.users.edit.documents.depositBy"})} ${document.userModifierEmail}` : "No deposit user"; 
}

const EditUserDocumentsTab = ({ userId = null }) => {
    const intl = useIntl();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const styles = {
        gridContainer: {
            rowSpacing: 1,
            columnSpacing: 1,
            style: {
                // border: "1px solid orange",
                width: "100%", 
                height: "100%",
                margin: "0px 0px 0px",
                padding: "0px 0px 0px"
            }    
        },
        typoUserName: {
            color: "grey",
            fontWeight: 400
        },
        typoTitle: {
            color: "grey",
            fontWeight: 400,
            fontSize: "1rem",
            lineHeight: 1.5,
            letterSpacing: 0.0093
        },
        gridItem: {
            rowSpacing: 0,
            columnSpacing: 0,
            style: {
                // border: "1px solid blue",
                width: "100%", 
                height: "100%",
                margin: "0px 0px 0px",
                padding: "0px 8px 0px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px"
            }
        },
        listItem: {
            text: { 
                root: {
                    fontSize: "1rem", 
                }
            },
        }
    }
    
    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    const [user, setUser] = useState({
        userId: null,
        firstName: "",
        lastName: "",
        universityId: null,
        universityAcronym: "",
        universityName: "",
        documents: []
      });

    const [userConnected, setUserConnected] = useState();
    const [refreshList, setRefreshList] = useState(0);
    const [loading, setLoading] = useState(false);

    const [pdfData, setPdfData] = useState("");
    const [showPDF, setShowPDF] = useState(false);

    const [documentToDeleteId, setDocumentToDeleteId] = useState(null);
    const [documentToDeleteLabel, setDocumentToDeleteLabel] = useState(null);

    const [resultMessage, setResultMessage] = useState("");
    const [openSuccess, setOpenSuccess] = useState(false);
    const [openError, setOpenError] = useState(false);
    const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false);

    useEffect(() => {
        let mounted = true;
        const fetchUserDocuments = async () => {
            setLoading(true);
            try {
                const results = await userService.findUserDocumentsByUserId(userId);
                const user = results.data;
                if (mounted) {
                    setUser(user);
                }
            } catch (error) {
                console.error("error fetching user's documents", error);
            } finally {
                setLoading(false);
            }
        }
        if (!!userId) {
            fetchUserDocuments();
        }
        return () => { mounted = false };
    }, [refreshList]);


    useEffect(() => {
        let mounted = true;
        async function fetchConnectedUser() {
            try {
                let user = await getUser();
                if(mounted) {
                    setUserConnected(user.data);
                }
            } catch (error) {
                console.error("error fetching connected user", error);
            }
        }
        fetchConnectedUser();
        return () => {
            mounted = false;
        }
    }, []);

    

    const handleAddDocumentToUser = async (event) => {
        setOpenSuccess(false);
        setOpenError(false);

        if(event == null || event == undefined || event.target == null || event.target == undefined){
            console.error("event.target doesn't exist", event);
        } 

        const selectedFile = event.target.files[0];

        if (!selectedFile) {
            setResultMessage("backoffice.users.edit.documents.add.error.noFile");
            setOpenError(true);
            return;
        }
    
        if (selectedFile.type !== 'application/pdf') {
            setResultMessage("backoffice.users.edit.documents.add.error.type");
            setOpenError(true);
            return;
        }

        const maxSize = 1000 * 1024; // 1000ko in bytes -> 1 Mo
        if (selectedFile.size > maxSize) {
            setResultMessage("backoffice.users.edit.documents.add.error.size");
            setOpenError(true);
            return;
        }
        const userDocumentInfos = {
            documentName: selectedFile?.name,
            documentSize: selectedFile?.size,
            documentType: selectedFile?.type,
            userOwnerId: user?.userId,
            userOwnerUniversityId: user?.universityId,
            userModifierId: userConnected?.cvcUser?.userId,
            userModifierEmail: userConnected?.cvcUser?.email
        };

        userService
            .addDocumentToUser(userId, userDocumentInfos, selectedFile)
            .then(() => {
                setResultMessage("backoffice.users.edit.documents.add.success");
                setOpenSuccess(true);
                setRefreshList(refreshList + 1);
            })
            .catch((error) => {
                handleApiCallError(error,  "backoffice.users.edit.documents.add.error");
            });
    };

    const handleApiCallError = (error, errLabel) => {
        if (error && error.data && error.data.errorCode) {
            errLabel = error.data.errorCode;
        }
        console.error(intl.formatMessage({id: errLabel}), error);
        setResultMessage(errLabel);
        setOpenError(true);
    }

    const handleClickDelete = (document) => {
        setOpenDeleteConfirmDialog(true);
        setDocumentToDeleteId(document.documentId);
        setDocumentToDeleteLabel(`${getDocNameLabel(document)}`);
    }

    const handleCloseDeleteConfirmDialog = () => {
        setOpenDeleteConfirmDialog(false);
    }
    const handleDeleteFile = () => {
        setOpenSuccess(false);
        setOpenError(false);
        setOpenDeleteConfirmDialog(false);
        const deleteDocumentToUserInfos = {
            documentId: documentToDeleteId,
            userId: userId,
            userUniversityId: user?.universityId
        };
        userService
            .deleteDocumentToUser(deleteDocumentToUserInfos)
            .then((response) => {
                setResultMessage("backoffice.users.edit.documents.delete.success");
                setOpenSuccess(true);
                setRefreshList(refreshList + 1);
            })
            .catch((error) => {
                handleApiCallError(error, "backoffice.users.edit.documents.delete.error");
            });
    }

    const handleViewPdf = (doc) => {
        userService
        .findUserDocumentsByDocumentId(doc.userOwnerId, doc.documentId)
        .then((response) => { 
            if(isMobile){
                if(typeof window !== "undefined"){
                    let alink = document.createElement("a");
                    alink.href = `data:application/pdf;base64,${response.data.documentData}`;
                    alink.download = `${doc.documentName}`;
                    alink.click();
                } else {
                    console.error("Global objects such as window or document are not available");
                }
            } else {
                setPdfData(response.data.documentData);
                setShowPDF(true);
            }   
        })
        .catch((error) => {
            handleApiCallError(error,  "backoffice.users.edit.documents.view.error");
        });
    }

    const handleClosePdfDialog = () => {
        setShowPDF(false);
    }

    return (
    <>
        <ResultSnackbars
            openError={openError}
            openSuccess={openSuccess}
            messageId={resultMessage}
            setOpenSuccess={setOpenSuccess}
            setOpenError={setOpenError}
        />

        <Grid container 
            columnSpacing={ styles.gridContainer.columnSpacing }
            rowSpacing={ styles.gridContainer.rowSpacing }
            style={ styles.gridContainer.style }
        >
            <Grid item xs={12} md={6} textAlign={{ xs: "left", md: "left" }}>
                <Typography sx={styles.typoUserName}>
                    { capitalizeFirstLetter(user?.firstName) + ' ' + user?.lastName?.toUpperCase() 
                    + ' ( ' +  user?.universityAcronym+ ' - ' + user?.universityName +  ' )'
                    }
                </Typography>
            </Grid>

            <Grid item xs={12} md={12} textAlign={{ xs: "left", md: "left" }}>
                <Typography sx={styles.typoTitle} variant="h6" component="div">
                    { intl.formatMessage({id: "backoffice.users.edit.documents.title"})}
                </Typography>
            </Grid>

            <CvcViewPdfDialog 
                open={showPDF}
                onClose={handleClosePdfDialog} 
                base64PdfData={pdfData}            
            />
        
            <Grid item xs={12} md={12} textAlign={{ xs: "left", md: "center" }}>
                <DocumentList 
                        loading={loading} 
                        documents={user.documents} 
                        handleClickDelete={handleClickDelete} 
                        handleViewPdf={handleViewPdf}
                        styles={styles}
                />
            </Grid>

            <Grid item xs={12} md={12} textAlign={{ xs: "center", md: "center" }}>
                <Button component="label" variant="contained" tabIndex={-1} startIcon={<CloudUpload />}>
                    { intl.formatMessage({id: "backoffice.users.edit.documents.addButton"})}
                    <VisuallyHiddenInput type="file" accept=".pdf" onChange={handleAddDocumentToUser} />                    
                </Button>
                <ConfirmationDialog listingUrl={`/back-office/users`} />
            </Grid>
            
            <CvcConfirmDeleteDialog 
                open={openDeleteConfirmDialog}
                itemToDeleteLabel={documentToDeleteLabel}
                onDelete={handleDeleteFile} 
                onClose={handleCloseDeleteConfirmDialog} 
                onCancel={handleCloseDeleteConfirmDialog}            
            />
        </Grid>
    </>
    );
}

const DocumentList = ({ loading, documents, handleClickDelete, handleViewPdf, styles }) => {
    const intl = useIntl();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    return ( 
        <>
            {
                loading ?
                    <CircularProgress/> 
                    :
                    documents.length == 0 ?
                        <Typography sx={{ float: "left"}} >
                            { intl.formatMessage({ id: "myDocuments.noDocument" }) }
                        </Typography>
                        : 
                        documents.map( document => (
                                <ListItem key={document.documentId}>
                                    <ListItemAvatar>
                                        <IconButton 
                                            edge="end" 
                                            aria-label="delete"
                                            onClick={e => handleClickDelete(document)}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </ListItemAvatar>
                                    <ListItemAvatar>
                                        <IconButton 
                                            edge="start" 
                                            aria-label="see"
                                            onClick={e => handleViewPdf(document)}
                                        >
                                            { isMobile ? 
                                                <FileDownload />
                                                : 
                                                <Visibility />
                                            }
                                        </IconButton>
                                    </ListItemAvatar>
                                    <Tooltip title={`${getDocDepositUserLabel(document, intl)}`} placement="top-start" arrow={true}>
                                        <ListItemText
                                            style={styles.listItem.text}
                                            primary={`${getDocDepositDateLabel(document, intl.locale)} - ${getDocNameLabel(document)} - ${getDocSizeLabel(document)}`}
                                        />
                                    </Tooltip>
                                </ListItem>
                            )
                        )
            }
        </>
    )
}

export default EditUserDocumentsTab;