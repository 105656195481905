import React, { useEffect } from "react";

import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Modal from '@mui/material/Modal';
import {Button, CircularProgress, Dialog, DialogContent, FormGroup, FormLabel, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useFieldArray, useFormContext} from "react-hook-form";

import {GROUP} from "utils/constants/back-office-constants";
import {DISABLED_CONDITION} from "utils/courses-schema";
import { RhfListOfValuesSelect, RhfTextField } from 'components/backoffice/common/RhfFields';
import MultiLinkSelect from "components/backoffice/catalog/course/components/MultiLinkSelect";
import MultiTeacherSelect from "components/backoffice/catalog/course/components/MultiTeacherSelect";

import RhfDateTimePicker from "ui/RhfDateTimePicker";
import { useIntl } from "gatsby-plugin-intl";

const AddEditCourseSessionForm = ({ classes, control, submitForm }) => {

  const intl = useIntl();
  const methods = useFormContext();
  const { formState: { errors }, register } = methods;
  const courseType = methods.watch("type");
  const { fields, append, remove } = useFieldArray({
    name: "sessions",
    control,
  });
  const [isLoading, setIsLoading] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setExpanded(fields.length > 0 ? fields.length - 1 : false);
    }
    return () => {
      mounted = false;
    }
  }, [setExpanded, fields, isLoading]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleStartDateChanged = (index) => {
    methods.setValue(`sessions[${index}].endDate`,methods.getValues(`sessions[${index}].startDate`));
  }

  const addNewSession = () => {
      
    append({
      key: "",
      format: "",
      language: "",
      title: "",
      startDate: null,
      endDate: null,
      sessionId: "",
      links: [{ description: "", type: "", link: "" }],
      teachers: [
        {
          gender: "",
          firstName: "",
          lastName: "",
          email: "",
          title: "",
          university: "",
        },
      ],
    })

  };


  const saveAndAddNewSession = async () => {
    setIsLoading(true);
    submitForm()
    .then((res) => {      
      if (res) {
        addNewSession();
      } else {
        console.log("session not saved", res);
      }
    })
    .finally(() => {
      setIsLoading(false);
    });
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'transparent',
  };
  
  return fields.map((session, i) => {
    return (
      <Accordion
        expanded={expanded === i}
        onChange={handleChange(i)}
        key={session.id}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id={`session-${i}-header`}
        >
          <div className={classes.column}>
            <Typography className={classes.heading}>{intl.formatMessage({id:"course.to.session"}).toUpperCase()} {i+1}</Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>

        {(expanded === i ) && 
        <Modal
          open={isLoading}
          aria-labelledby="Loading modal"
          aria-describedby="Waiting for saving session"
        >
          <Box sx={style}>
            <CircularProgress />
          </Box>
        </Modal>}
          <Grid container item xs={12}>
            <input
              type="hidden"
              {...register(`sessions.${i}.sessionId`)}
            ></input>
            <Box pb={8}>
              <FormGroup row>
                <Grid container spacing={2}>
                  <Grid item xl={12} md={12} sm={12} style={{marginBottom: "15px"}}>
                    <RhfTextField
                        label="session.title"
                        name={`sessions[${i}].title`}
                        defaultValue={session.title}
                        className={classes.textFieldLarge}
                        control={control}
                        required
                        error={errors 
                                  && errors.sessions 
                                  && errors.sessions[i] 
                                  && errors.sessions[i].title? 
                                  errors.sessions[i].title : null}
                      />
                  </Grid>
                 
                  <Grid item xl={3} md={4} sm={12}>
                    <RhfDateTimePicker
                      control={control}
                      name={`sessions[${i}].startDate`}
                      label="session.startDate"
                      format="dd/MM/yyyy HH:mm"
                      defaultValue={session.startDate}
                      onChange={() => { handleStartDateChanged(i); }
                      }
                      error={errors 
                              && errors.sessions 
                              && errors.sessions[i]
                              && errors.sessions[i].startDate ? 
                              errors.sessions[i].startDate : null}
                    />
                  </Grid>
                  
                  <Grid item xl={9} md={8} sm={12}>
                    <RhfDateTimePicker
                      control={control}
                      name={`sessions[${i}].endDate`}
                      label="session.endDate"
                      format="dd/MM/yyyy HH:mm"
                      defaultValue={session.endDate}
                      error={
                              errors.sessions 
                              && errors.sessions 
                              && errors.sessions[i] 
                              && errors.sessions[i].endDate ? 
                              errors.sessions[i].endDate : null
                      }                    
                    />
                  </Grid>
                  <Grid item xl={3} md={4} xs={12}>
                    <RhfTextField
                      label="session.pkey"
                      name={`sessions.${i}.key`}
                      defaultValue={session.key}
                      className={classes.textField}
                      control={control}
                    />
                  </Grid>
                  <Grid item xl={6} md={4} xs={12}>
                    <RhfListOfValuesSelect
                      name={`sessions[${i}].language`}
                      control={control}
                      label={"session.language"}
                      group={GROUP.language}
                      className={classes.textField}
                      defaultValue={session.language}
                    />
                  </Grid>
                  <Grid item xl={3} md={4} xs={12}>
                  <RhfListOfValuesSelect
                      name={`sessions.${i}.format`}
                      defaultValue={session.format}
                      className={classes.textField}
                      label={"session.format"}
                      group={GROUP.sessionFormat}
                      control={control}
                    />
                 
                  </Grid>
                 
                  <Grid item xl={12} md={12} sm={12}>
                    <RhfTextField
                      label="session.short.description"
                      name={`sessions[${i}].shortDescription`}
                      defaultValue={session.shortDescription}
                      multiline={true}
                      rows={3}
                      className={classes.textFieldLarge}
                      control={control}
                    />
                  </Grid>
                 
                  <Grid item xs={12}>
                  
                      <FormLabel component="legend">
                        {intl.formatMessage({ id: "session.links" })}
                      </FormLabel>
                      <MultiLinkSelect
                        control={control}
                        name={`sessions.${i}.links`}
                        register={methods.register}
                        //className={classes.textField}
                      />
               
                  </Grid>
                  <Grid item xs={12}>
                    
                      <FormLabel component="legend">
                        {intl.formatMessage({ id: "session.teachers" })}
                      </FormLabel>
                      <MultiTeacherSelect
                        //classes={classes}
                        control={control}
                        register={methods.register}
                        name={`sessions[${i}].teachers`}                        
                        disabled={DISABLED_CONDITION["session.teachers"].disabled(courseType)}
                        errors={errors.sessions && errors.sessions[i] ? errors.sessions[i].teachers : null }
                        setValue={methods.setValue}
                        getValues={methods.getValues}
                        //teachers={methods.getValues(`sessions[${i}].teachers`)}
                        //reset={methods.reset}
                      />
               
                  </Grid>
                </Grid>
              </FormGroup>
            </Box>
          </Grid>
        </AccordionDetails>
        <Divider />
        <AccordionActions>
          {fields.length !== 1 && (
            <Button
              onClick={async () => {
                remove(i);
              }}
              title={intl.formatMessage({
                id: "course.session.delete",
              })}
              color="secondary"
            >
              <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
            </Button>
          )}
          {fields.length - 1 === i && (
            <Button
              variant="outlined"
              color="primary"
              title={intl.formatMessage({
                id: "course.session.saveAndAdd",
              })}
              onClick={() => saveAndAddNewSession()}
              color="primary"
            >
              {intl.formatMessage({ id: "course.session.saveAndAdd" })}
            </Button>
          )}
        </AccordionActions>
         
      </Accordion>
    );
  });
};

export default AddEditCourseSessionForm;
