import React from "react";

import { Button, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import {faTimes, faUpload} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { useIntl } from "gatsby-plugin-intl";
import themeMuiV5 from "components/common/ThemeMuiV5";



const styles = {
    uploadIcon: {
        marginRight: themeMuiV5.spacing(2),
    },
    deleteIcon: {
        marginLeft: themeMuiV5.spacing(2),
    },
    fileClass: {
        color: themeMuiV5.palette.primary.main,
    }
}


const CvcUploadImage = ({ 
    id, label, accept = "image/*", 
    handleDeleteCoverImage, 
    handleChangeCoverImage, 
    selectedFileName,
    disabled = false    
}) => {

    const intl = useIntl();    
    const handleClick = (event)=> { 
        event.target.value = null;
    }
    return (
    <Grid id={id} container spacing={3}>
        <Grid item xs={12} md={4}>
            <label htmlFor="upload-cover-image" title="upload cover image">            
                <Button
                    color="primary"
                    variant="outlined"
                    component="span"
                    disabled={disabled}
                >
                    <FontAwesomeIcon icon={faUpload} style={styles.uploadIcon}/> { intl.formatMessage({ id: label })} 
                    <input
                        id="upload-cover-image"
                        name="coverImage"
                        type="file"
                        accept={accept}
                        disabled={disabled}
                        style={{ display: "none" }}                
                        onChange={handleChangeCoverImage}
                        onClick={handleClick}                 
                    />
                </Button>
            </label>
        </Grid>

        <Grid item xs={12} md={8}>
            { selectedFileName  && (
                <span style={styles.fileClass}>
                    { selectedFileName }
                    <Tooltip title='Delete'>
                        <IconButton
                            aria-label="deleteImage"
                            color="primary"
                            size="small"
                            onClick={handleDeleteCoverImage}
                            style={styles.deleteIcon}                        
                        >
                            <FontAwesomeIcon icon={faTimes} />
                        </IconButton>
                    </Tooltip>
                </span>
            )}
        </Grid>
    </Grid>
    );
}

export default CvcUploadImage;