import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchListOfValuesForceRetrieve, GROUP_STATE } from "state/common/listOfValueSlice";

import { TextField } from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import { useIntl } from "gatsby-plugin-intl";
import { Controller } from "react-hook-form";


const RhfAutoCompleteMultiChoice = ({
    id,
    name,
    group,    
    label,    
    control,
    placeholder = null,
    disabled = false,
    required = false, 
    error = null,
    sxAutoComplete = {},
    sxInput = {}
}) => {

    const intl = useIntl();

    const dispatch = useDispatch();
    useEffect(() => {
        async function fetchData() {
            try {
                dispatch(fetchListOfValuesForceRetrieve(group)); 
            } catch (error) {
                console.error(`error fetching list of values ${group} `, error);
            }
        }
        fetchData();        
    }, []);
    const options = useSelector(GROUP_STATE[group].state);   
    
    
    const handleIsOptionEqualToValue = (option, value) => {
        return option.value === value.value
    }; 


    return (
            <Controller
                name={name}
                control={control}
                render={ ({ field }) => {
                        const { onChange, onBlur, value } = field;
                        return (
                            <Autocomplete
                                id={id}
                                sx={sxAutoComplete}
                                disabled={disabled}
                                multiple
                                filterSelectedOptions
                                options={options}
                                getOptionLabel={(option) => option.label}
                                isOptionEqualToValue={handleIsOptionEqualToValue}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        sx={sxInput}
                                        required={required}
                                        variant="standard"
                                        label={intl.formatMessage({ id: label })} 
                                        placeholder={ placeholder ? intl.formatMessage({ id: placeholder }) : '' }
                                        error={!!error}
                                        helperText={error ? intl.formatMessage({id: error.message}) : null}
                                    />
                                )}
                                onChange={(event, selectedOptions) => {
                                    onChange(selectedOptions);
                                }}
                                onBlur={onBlur}
                                value={value || []}
                            />
                        )
                    }
                }
            />        
    );
};

export default RhfAutoCompleteMultiChoice;


