import React from "react";

import Grid from '@mui/material/Grid';
import { IconButton, TextField, Tooltip } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import ReplayIcon from '@mui/icons-material/Replay';

const CvcFilterField = ({ label, filterTerm, onFilterTermChange, onDeleteFilterTerm }) => {
    return (
        <div style={{ display: 'flex', alignItems: 'center'}}>        
            <SearchIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
            <TextField 
                id="filter-term"
                label={label}
                sx={{ width: '70%', marginBottom: '15px' }}
                value={filterTerm}
                size='small'
                variant='standard'
                onChange={onFilterTermChange}
            />
            <Tooltip title='Delete filter term'>                    
                <IconButton
                    aria-label="deleteFilterTerm"
                    color="primary"
                    size="small"
                    onClick={onDeleteFilterTerm}
                    sx={{ width: '24px', height: '24px', margin: 0 }}
                >
                    <ClearIcon sx={{ color: 'action.active', ml: 1, my: 0 }} />
                </IconButton> 
            </Tooltip> 
        </div>
    );
}

export default CvcFilterField;