import React, { useEffect, useRef, useState } from "react";

import { VariableSizeList as List } from "react-window";

import Paper from '@mui/material/Paper';
import ListItemText from '@mui/material/ListItemText';
import { Grid, IconButton, ListItem, ListItemIcon, Tooltip } from "@mui/material";
import ReplayIcon from '@mui/icons-material/Replay';

import CvcFilterField from "ui/CvcFilterField";
import compareByLabel from "ui/CvcListUtils";

const CvcListVirtualized = ({     
    items: values = [], 
    showFilter = false, filterLabel = 'Filter Term',
    showReloadData = false, onReloadData=null,
    handleSort = compareByLabel, 
    width='100%', height='100%', listHeight=440
}) => {

  const listRef = useRef({});
  const rowHeights = useRef({});

  const [filterTerm, setFilterTerm] = useState('');
  const [items, setItems] = useState(values);
  const [filteredItems, setFilteredItems] = useState(values);


  useEffect(()=> {
    const debouncerTime = setTimeout(()=> {
        filterItems(filterTerm);
    }, 200);
    return () => { clearTimeout(debouncerTime); }
  }, [filterTerm]);

  const filterItems = (filterTerm) => {
    if(filterTerm != ''){
        const filteredData = items.filter(item => item?.label?.toLowerCase().includes(filterTerm.toLowerCase()));
        setFilteredItems(filteredData);        
    } else {
      setFilteredItems(items);
    }          
}

  const handleFilterTermChange = (e) => {
    setFilterTerm(e.target.value);                
  }

  const handleDeleteFilterTerm = () => {
    setFilterTerm('');
    setFilteredItems(items);
  }
  
  function getRowHeight(index) {
    return rowHeights.current[index] + 8 || 82;
  }

  function Row ({ index, style }) {
    const rowRef = useRef({});

    useEffect(() => {
      if (rowRef.current) {
        setRowHeight(index, rowRef.current.clientHeight);
      }
    }, [rowRef]);
    
    return (
      <div style={style}>
          <div ref={rowRef}>
            <ListItem
              key={filteredItems[index].id}
              role="listItem"   
              disableGutters            
            >
              <ListItemIcon/>
              <ListItemText  primary={filteredItems[index].label} />            
            </ListItem>                   
          </div>
      </div>
    );
  }

  function setRowHeight(index, size) {
    listRef.current.resetAfterIndex(0);
    rowHeights.current = { ...rowHeights.current, [index]: size };
  }

  return (
    
    <div style={{ width: width, height: height }}>
      <Grid container width={width} sx={{ marginTop: '10px'}}>
        { (showReloadData && onReloadData) && 
            <Grid item xs={3} alignContent={'center'} textAlign={'start'}>               
                <Tooltip title='Reload data'>        
                    <IconButton
                        aria-label="reload data"
                        color="primary"
                        size="small"
                        onClick={onReloadData}                        
                        sx={{ width: '24px', height: '24px', margin: 0 }}
                    >
                        <ReplayIcon sx={{ color: 'action.active', ml: 1, my: 0 }} />
                    </IconButton>
                </Tooltip>
            </Grid>    
        }
        { showFilter &&           
          <Grid item xs={9}>        
              <CvcFilterField 
                label={filterLabel} 
                filterTerm={filterTerm} 
                onFilterTermChange={handleFilterTermChange} 
                onDeleteFilterTerm={handleDeleteFilterTerm} 
              />
          </Grid>
        }
          
      </Grid>
          
      <Paper sx={{ overflow: 'auto' }} 
          style={{ width: width, height: height }}
      >    
        <List
              ref={listRef}
              className="List"
              height={listHeight}
              width={'100%'}
              itemCount={filteredItems.length}
              itemSize={getRowHeight}            
        >
              {Row}
        </List>
      </Paper>
    </div>  
  );
};

export default CvcListVirtualized;
