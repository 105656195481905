import React from "react";

import { CircularProgress } from "@mui/material";


const CvcLoadingProgress = ({}) => {
    return (
        <div style={{ display: 'flex', 
            justifyContent: 'center', 
            alignContent: 'center',
            marginTop: '20px'}} >                                    
            <CircularProgress size={40} disableShrink={true}/>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <h1>Loading ...</h1>                                    
        </div>  
    )
}

export default CvcLoadingProgress;

