import React, { useState } from 'react';
import * as headerStyles from 'styles/backoffice/header.module.scss';

import { Link, navigate, useIntl } from 'gatsby-plugin-intl';

import { Drawer, Grid, IconButton, List, ListItemButton, ListItemText } from '@mui/material';
import DehazeIcon from '@mui/icons-material/Dehaze';

import { containsRole } from 'services/auth';
import { CivicaLogo } from 'components/layout/CivicaLogo';
import { menuItems } from 'components/backoffice/common/layout/header/navbars/DesktopNavbarBo';

const MobileNavbarBo = () => {
    const intl = useIntl();
    const mobileMenuId = 'primary-search-account-menu-mobile';
    
    const [state, setState] = useState({
        mobileView: false,
        drawerOpen: false
    });
    const { drawerOpen } = state;

    const handleDrawerOpen = () =>
        setState((prevState) => ({...prevState, drawerOpen: true}));
        
    const handleDrawerClose = () =>
        setState((prevState) => ({...prevState, drawerOpen: false}));
        
    const listItems = menuItems.map((item, index) =>
        <React.Fragment key={index}>
        { process.env.SHOW_ADMIN && containsRole(item.authorizedRoles) ?
            <ListItemButton onClick={() => navigate(item.link)} >
                <ListItemText primary={intl.formatMessage({id: item.title})}/>
            </ListItemButton>
             : <></>
        }
        </React.Fragment>
    );
    return (
        <>
            <Grid className={headerStyles.navBarMobile}>

                <IconButton
                    aria-label="show more"
                    aria-controls={mobileMenuId}
                    aria-haspopup="true"
                    onClick={handleDrawerOpen}
                    color="inherit"
                    classes={{root: headerStyles.iconButton, label: headerStyles.iconButtonLabel}}
                    variant="outlined"
                    disableRipple={false}
                    id="menuButton"
                    >
                    <DehazeIcon/> Menu
                </IconButton>
            </Grid>

            <Drawer
                {...{
                    anchor: "right",
                    open: drawerOpen,
                    onClose: handleDrawerClose,
                }}
            >
                <Link to="/">
                    <div className={headerStyles.logo}>
                        <CivicaLogo/>
                    </div>
                </Link>
                <List dense
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    className={headerStyles.drawerListRoot}
                >                  
                    { listItems }
                </List>
            </Drawer>
        </>
    );
}

export default MobileNavbarBo;