const isPermissionError = (errorStatus) => {
    return 401 == errorStatus || 403 == errorStatus;
}

const handleError = (intl, error, defaultErrorMessage, showErrorCallback = null) => {        
    let errorMessage = null;
    if (error.status && isPermissionError(error.status)) {
        errorMessage = intl.formatMessage({ id:  "course.error.permission" });
        console.error(errorMessage, error);
        if(showErrorCallback) showErrorCallback(errorMessage);
    } else {
        console.error(defaultErrorMessage, error);
        if(showErrorCallback) {
            const messageId = error && error.data && error.data.errorCode ? error.data.errorCode : null;
            errorMessage = messageId ? intl.formatMessage({ id: messageId }) : defaultErrorMessage;         
            if(errorMessage) showErrorCallback(errorMessage);
            //else if(error && error.data) showErrorCallback(`${defaultErrorMessage}: ${error.data}`);
        }
    }
}

export { isPermissionError, handleError };