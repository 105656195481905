import React from "react";

import {Button, FormControl, FormGroup, FormLabel, Grid,} from "@mui/material";

import {faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useFieldArray, useFormContext} from "react-hook-form";
import { useIntl} from "gatsby-plugin-intl";

import {INPUT_MAX_LENGTH } from "../../../../../utils/constants/constants";
import {GROUP} from "../../../../../utils/constants/back-office-constants";
import {DISABLED_CONDITION} from "../../../../../utils/courses-schema";
import {
    RhfListOfValuesSelect,
    RhfSwitch,
    RhfTextField
} from 'components/backoffice/common/RhfFields';
import MultiLinkSelect from "components/backoffice/catalog/course/components/MultiLinkSelect";
import MultiTeacherSelect from "components/backoffice/catalog/course/components/MultiTeacherSelect";
import RhfDatePicker from "ui/RhfDatePicker";
import RhfAutoCompleteMultiChoice from "ui/RhfAutoCompleteMultiChoice";
import RhfListOfValuesCheckboxes from "ui/RhfListOfValuesCheckboxes";



const style = {
  gridItem: {
    //border: "1px solid blue"
  }
}

const AddEditCourseForm = ({ className, control }) => {

  const intl = useIntl();
  const {
    formState: { errors },
    register, reset, watch, setValue, getValues
  } = useFormContext();
  const courseType = watch("type");

  const {
    fields: topicFields,
    append: topicAppend,
    remove: topicRemove,
  } = useFieldArray({ name: "topics", control });
  
  return (
    <Grid container spacing={3}>

      <Grid item xs={12} md={6} lg={4} style={style.gridItem}>
        <RhfListOfValuesSelect
          id="course-type"
          name="type"
          label={"course.type"}
          group={GROUP.courseType}
          control={control}
          className={className.textField}
          required          
          error={errors ? errors.type : null}
        />
      </Grid>

      <Grid item xs={12} md={6} lg={4} style={style.gridItem}>
        <RhfListOfValuesSelect
          id="course-subtype"
          name="subtype"
          label="course.subtype"
          group={GROUP.courseSubtype}
          control={control}
          className={className.textField}
          disabled={DISABLED_CONDITION["course.subtype"].disabled(courseType)}
          required={DISABLED_CONDITION["course.subtype"].required(courseType)}          
          error={errors ? errors.subtype : null}
        />
      </Grid>

      <Grid item xs={12} md={6} lg={4} style={style.gridItem}>
          <RhfListOfValuesSelect
              id="course-period"
              name="period"
              label="course.period"
              group={GROUP.period}
              control={control}
              className={className.textField}
              disabled={DISABLED_CONDITION["course.period"].disabled(courseType)}
              required={DISABLED_CONDITION["course.period"].required(courseType)}
              error={errors ? errors.period : null}
          />
      </Grid>

      <Grid item xs={12} md={6} lg={4} style={style.gridItem}>
        <RhfTextField
          id="course-code"
          label="course.code"
          name="code"
          className={className.textField}
          control={control}
          disabled={DISABLED_CONDITION["course.code"].disabled(courseType)}
          required={DISABLED_CONDITION["course.code"].required(courseType)}
          error={errors ? errors.code : null}
        />
      </Grid>

      <Grid item xs={12} md={6} lg={4} style={style.gridItem}>
        <RhfTextField
          id="course-key"
          label="course.key"
          name="key"
          className={className.textField}
          control={control}
          disabled={DISABLED_CONDITION["course.pkey"].disabled(courseType)}
          required={DISABLED_CONDITION["course.pkey"].required(courseType)}
          error={errors ? errors.key : null}
        />
      </Grid>

      <Grid item xs={12} md={6} lg={4} style={style.gridItem}>
        <RhfTextField
          id="course-multijoin-code"
          label="course.multi.join.code"
          name="multiJoinCode"
          className={className.textField}
          control={control}
          disabled={DISABLED_CONDITION["course.multiJoinCode"].disabled(courseType)}
          required={DISABLED_CONDITION["course.multiJoinCode"].required(courseType)}
          error={errors ? errors.multiJoinCode : null}
        />
      </Grid>

      <Grid item xs={12} md={6} lg={4} style={style.gridItem}>
        <RhfListOfValuesSelect
          id="course-main-university"
          name="university"
          label={"course.university"}
          group={GROUP.university}
          control={control}
          className={className.textField}
          required
          error={errors ? errors.university : null}
        />
      </Grid>

      <Grid item xs={12} md={6} lg={4} style={style.gridItem}>
        <RhfAutoCompleteMultiChoice
          id='course-secondary-universities'
          name={'secondaryUniversities'}
          label={'course.secondaryUniversities'}
          group={GROUP.university}    
          control={control}      
          //sxInput={className.textField}         
          disabled={DISABLED_CONDITION["course.secondaryUniversities"].disabled(courseType)}
          required={DISABLED_CONDITION["course.secondaryUniversities"].required(courseType)}
          error={errors ? errors.secondaryUniversities : null}
        />
      </Grid>

      <Grid item xs={12} md={6} lg={4} style={style.gridItem}>
          <RhfListOfValuesSelect
              id="course-logo"
              name="logo"
              label={"course.logo"}
              group={GROUP.logo}
              control={control}
              className={className.textField}
              required
              error={errors ? errors.logo : null}
          />
      </Grid>
      
      <Grid item xs={12} md={6} lg={4} style={style.gridItem}>
        <RhfListOfValuesCheckboxes
          name="cycles"
          label="course.cycle"
          sxFormControl={className.formControl}
          getValues={getValues}
          control={control}
          group={GROUP.courseCycle}
          required={true}
          error={errors ? errors.cycles : null}
        />
      </Grid>

      <Grid item xs={12} md={12} lg={8} style={ style.gridItem }>
        <GridSwitch 
          courseType={courseType}   
          className={className}   
          control={control} 
          errors={errors} 
        />
      </Grid>

      <Grid item xs={12} md={4} lg={4} style={style.gridItem}>
        <RhfSwitch
          id="course-is-open-to-registration"
          label="course.open.to.registration"
          name="openToRegistration"
          className={className.formControlLabel}
          control={control}
          disabled={DISABLED_CONDITION["course.openToRegistration"].disabled(courseType)}
          required={DISABLED_CONDITION["course.openToRegistration"].required(courseType)}
        />
      </Grid>

      <Grid item xs={12} md={4} lg={4} style={style.gridItem}>
        <RhfTextField
          id="course-max-registration"
          label="course.max.registration"
          name="maxRegistration"
          className={className.textField}
          control={control}
          disabled={DISABLED_CONDITION["course.maxRegistration"].disabled(courseType)}
        />
      </Grid>

      <Grid item xs={12} md={4} lg={4} style={style.gridItem}>
        <RhfDatePicker
          id="course-deadline-date"
          control={control}
          name="deadlineRegistration"
          label="course.deadline.registration"
          format="dd/MM/yyyy"
          className={className.textField}
          disabled={DISABLED_CONDITION["course.registrationDeadline"].disabled(courseType)} 
        />
      </Grid>

      <Grid item xs={12} style={style.gridItem}>
        <RhfTextField
          id="course-title"
          label="course.title"
          name="title"
          className={className.textFieldLarge}
          control={control}
          required
          error={errors ? errors.title : null}
          maxLength={INPUT_MAX_LENGTH.COURSE_TITLE}
        />
      </Grid>

      <Grid item xs={12} style={style.gridItem}>
        <input id="description-id-text" {...register("descriptionId")} 
          type="hidden"
        />
        <RhfTextField
          id="course-short-description"
          label="course.short.description"
          name="shortDescription"
          className={className.textFieldLarge}
          control={control}
          multiline={true}
          rows={5}
          required={DISABLED_CONDITION["course.shortDescription"].required(courseType
          )}
          error={errors ? errors.shortDescription : null}
          maxLength={INPUT_MAX_LENGTH.COURSE_SHORT_DESCRIPTION}

        />
      </Grid>

    
      <Grid item xs={12} style={style.gridItem}>
        <RhfTextField
          id="course-long-description"
          label="course.long.description"
          name="longDescription"
          className={className.textFieldLarge}
          control={control}
          multiline={true}
          rows={15}
          maxLength={INPUT_MAX_LENGTH.COURSE_LONG_DESCRIPTION}
        />
      </Grid>
      
      <Grid item xs={12} style={style.gridItem}>         
          <MultiLinkSelect
            name="links"
            control={control}            
            register={register}
            courseType={courseType}
            errors={errors}
          />
      </Grid>

      <Grid item xs={12} md={6} xl={4} style={style.gridItem}>
        <FormControl component="fieldset" style={{ width: '100%' }}>
          <FormLabel component="legend">
            {intl.formatMessage({ id: "course.topics" })}
          </FormLabel>
          {topicFields.map((topic, i) => {
            return (
              <FormGroup row key={topic.id}>
              <Grid container spacing={2} justifyContent={"center"}>
                
                <Grid item xs={10} style={{ width: '100%' }}>
                  <RhfListOfValuesSelect
                    name={`topics[${i}].id`}
                    defaultValue={topic.id}
                    label="course.topic"
                    group={GROUP.topic}
                    control={control}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={2} style={style.gridItem}>                
                  <Grid container direction='column' height='100%' alignContent='start' justifyContent='end'>
                    
                    { topicFields.length !== 1 && (
                      <Grid item>
                        <Button
                          onClick={() => topicRemove(i)}
                          title={intl.formatMessage({ id: "course.topic.delete" })}
                          color="secondary"
                        >
                          <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
                        </Button>
                      </Grid>
                    )}

                    { topicFields.length - 1 === i && (
                      <Grid item>
                        <Button
                          onClick={() => topicAppend({ id: "" , code: "" })}
                          title={intl.formatMessage({ id: "course.topic.add",})}
                          color="primary"
                        >
                          <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              </FormGroup>
            );
          })}
        </FormControl>
      </Grid>

      <Grid item xs={12} md={6} xl={8} style={style.gridItem}>
        <RhfTextField
          id="course-keywords"
          label="course.keywords"
          name="keywords"
          className={className.textField}
          control={control}
          multiline={true}
          rows={2}
        />
      </Grid>

      <Grid item xs={12} style={style.gridItem}>
          <FormLabel component="legend">
            {intl.formatMessage({ id: "course.teachers" })}
          </FormLabel>
          <MultiTeacherSelect
            control={control}
            name="teachers"
            register={register}
            disabled={DISABLED_CONDITION["course.teacher"].disabled(courseType)}
            errors={errors?.teachers}
            getValues={getValues}
            setValue={setValue}
            //reset={reset}          
          />
      </Grid>

      <Grid item xs={12} md={4} lg={3} style={style.gridItem}>
        <RhfTextField
          id="course-academic-email"
          label="course.academic.email"
          name="academicEmail"
          className={className.textField}
          control={control}
          disabled={DISABLED_CONDITION["course.academicEmail"].disabled(courseType)}          
          error={errors ? errors.academicEmail : null}
        />
      </Grid>
      <Grid item xs={12} md={4} lg={3} style={style.gridItem}>
        <RhfTextField
          id="course-help-desk-email"
          label="course.help.desk.email"
          name="helpDeskEmail"
          className={className.textField}
          control={control}
          disabled={DISABLED_CONDITION["course.helpDeskEmail"].disabled(courseType)}
          error={errors ? errors.helpDeskEmail : null}
        />
      </Grid>
    </Grid>
  );
};




const GridSwitch = ( { courseType, className, control, errors } ) => {
  
  return (
    <Grid item container rowSpacing={6}>

      <Grid item xs={12} md={6} style={style.gridItem}>
        <RhfListOfValuesSelect
          id="course-language"
          name="courseLanguage"
          label="course.course.language"
          group={GROUP.language}
          control={control}
          className={className.textField}
          required={DISABLED_CONDITION["course.courseLanguage"].required(courseType)}
          error={errors ? errors.courseLanguage : null}
        />
      </Grid>

      <Grid item xs={12} md={6} style={style.gridItem}>
        <RhfListOfValuesSelect
          id="course-description-language"
          name="language"
          label="course.descriptionLanguage"
          group={GROUP.language}
          control={control}
          className={className.textField}
          required={DISABLED_CONDITION["course.descriptionLanguage"].required(courseType)}
          error={errors ? errors.language : null}
        />
      </Grid>

      <Grid item xs={12} md={4} style={style.gridItem}>
        <RhfSwitch
          id="course-is-visible"
          label="course.visible"
          name="visible"
          className={className.formControlLabel}
          control={control}
        />
      </Grid>

      <Grid item xs={12} md={4} style={style.gridItem}>
        <RhfSwitch
          id="course-is-engage"
          label="course.engage.course"
          name="engageCourse"
          className={className.formControlLabel}
          control={control}
          disabled={DISABLED_CONDITION["course.engageCourse"].disabled(courseType)}
        />
      </Grid>

      <Grid item xs={12} md={4} style={style.gridItem}>
        <RhfSwitch
          id="course-is-private"
          label="course.private.course"
          name="privateCourse"
          className={className.formControlLabel}
          control={control}
        />
      </Grid>
    </Grid>
  );
}

export default AddEditCourseForm;
