


const compareByLabel = (item1, item2) => {
    if (item1.label < item2.label) {
        return -1;
      }
      if (item1.label > item2.label) {
        return 1;
      }
      return 0;
}

export default compareByLabel;