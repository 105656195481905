
import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";


const CvcConfirmActionDialog = ({ open, title, contentText, onClose, cancelBtnLabel, onCancel, actionBtnLabel, onAction }) => {  
    return (
      <div>
        <Dialog
            maxWidth={"sm"}
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {title}
          </DialogTitle>
          
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {contentText}
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button onClick={onCancel} autoFocus>{cancelBtnLabel}</Button>
            <Button onClick={onAction}>{actionBtnLabel}</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  export default CvcConfirmActionDialog;