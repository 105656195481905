import React, { useEffect, useState } from "react";

import Button from "@mui/material/Button/Button";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import { useIntl } from "gatsby-plugin-intl";

import repositoryService from "services/api/repositoryService";
import { handleError } from "utils/error/error-utils";
import CvcTransferList from "ui/CvcTransferList";
import CvcLoadingProgress from "ui/CvcLoadingProgress";
import CvcAutocompleteWithAdd from "ui/CvcAutocompleteWithAdd";
import CvcConfirmActionDialog from "ui/CvcConfirmActionDialog";
import CvcListVirtualized from "ui/CvcListVirtualized";


const StandardizeReferenceDatasTab = ({ selectedReferenceTable, setDisabledTables, showErrorMessage, showSuccessMessage }) => {

    const intl = useIntl();
    const [loadingDatas, setLoadingDatas] = useState(false);
    const [reloadDatas, setReloadDatas] = useState(true); 
    const [referenceDataList, setReferenceDataList] = useState([]);
    const [showStepOne, setShowStepOne] = useState(true);
    const [showStepTwo, setShowStepTwo] = useState(false);

    
    const [selectedData, setSelectedData] = useState([]);     
    const [selectedDestination, setSelectedDestination] = useState(null); 
    const [disabledStandardizeBtn, setDisabledStandardizeBtn]= useState(true);
    const [disabledValidateBtn, setDisabledValidateBtn]= useState(true);    
    
    
    useEffect(() => {
        let mounted = true;
        if(mounted){
            setReloadDatas(true);
        }      
        return () => { mounted=false; };
    }, [selectedReferenceTable]);


    useEffect(() => {
        let mounted = true;
        const fetchData = async () => {
            setLoadingDatas(true);
            try {
                const result = await repositoryService.findReferenceDataByTable(selectedReferenceTable.value);
                if(mounted){                    
                    const referenceDataList = result.data.map(item => { 
                        item.label = item.label + ' ('+item.count +')';
                        return item;
                     });
                    setReferenceDataList(referenceDataList);
                    setSelectedData([]);                                                      
                }                                
            } catch (error) {
                handleError(
                    intl, error, 
                    intl.formatMessage({ id: 'backoffice.repositories.fetchValues.error'}), 
                    showErrorMessage
                );
            } finally {                
                setLoadingDatas(false);
                setReloadDatas(false);
            }
        };
        if(reloadDatas && !loadingDatas) fetchData();        
        return () => { mounted=false; };
    }, [reloadDatas]);


    const handleSelectedReferenceDataChange = (selectedItems) => {
        setSelectedData(selectedItems);
        if(selectedItems && selectedItems.length > 0){
            setDisabledStandardizeBtn(false);
        } else {
            setDisabledStandardizeBtn(true);
        }       
    }

    const handleStandardize = () => {
        goToStepTwo();
    }

    const handleReloadReferenceDataList = () => {
        setSelectedData([]);
        setReloadDatas(true);
    }

    const handleSelectDestination = (newValue) => {
        setSelectedDestination(newValue);
        if(newValue){
            setDisabledValidateBtn(false);
        } else {
            setDisabledValidateBtn(true);
        }
    }

    const handleCancel = () => {
        goToStepOne();
    }
    
    

    const handleValidateStandardization = async () => {
        try {
            const standardizeReferenceDataDto = {
                referenceValuesToTreat: selectedData.map(data => data.id),
                destinationValue: selectedDestination
            }                                            
            await repositoryService.standardizeReferenceData(standardizeReferenceDataDto);
            goToStepOne();        
            showSuccessMessage(intl.formatMessage({ id: 'backoffice.repositories.standardisation.standardise.success'}));            
            setReloadDatas(true);     
        } catch (error) {
            handleError(
                intl, error, 
                intl.formatMessage({ id: 'backoffice.repositories.standardisation.standardise.error'}), 
                showErrorMessage
            );
        }
    }

    const goToStepOne = () => {
        setShowStepOne(true);
        setShowStepTwo(false);        
        setDisabledTables(false);        
    }

    const goToStepTwo = () => {        
        setShowStepOne(false);
        setShowStepTwo(true);
        setDisabledTables(true); 
        setSelectedDestination(null);
        setDisabledValidateBtn(true); 
    }

    return (<>
        { loadingDatas &&  <CvcLoadingProgress /> }
        { !loadingDatas && showStepOne &&
            <SelectDataPanel
                disabledStandardizeBtn={disabledStandardizeBtn} 
                referenceDataList={referenceDataList} 
                selectedData={selectedData}
                onChange={handleSelectedReferenceDataChange}                                 
                onReloadData={handleReloadReferenceDataList}
                onStandardize={handleStandardize}
            />
        } 
        { !loadingDatas && showStepTwo &&
            <DestinationPanel 
                disabledValidateBtn={disabledValidateBtn}
                selectedReferenceTable={selectedReferenceTable}
                selectedData={selectedData}
                selectedDestination={selectedDestination}
                onSelectDestination={handleSelectDestination}                                                 
                onCancel={handleCancel}
                onValidate={handleValidateStandardization}
            />
        }        
    </>);
}

const SelectDataPanel = ({ referenceDataList, selectedData, onChange, onReloadData, onStandardize, disabledStandardizeBtn }) => {
    const intl = useIntl();
    const showActions = {
        showReloadData: true,
        showMoveAllRight: false,
        showMoveAllLeft: true,
        showMoveSelectedRight: true,
        showMoveSelectedLeft: true
    }
    return (
        <Grid container alignItems='center' 
                sx={{ width: '100%', height: '100%' }}
        >                
            <Grid item width='calc(100% - 140px)'>

                <Grid container marginTop={'20px'}>
                    <Grid item width={'calc((100% - 102px)/2 + 102px)'}>
                        <Typography component="p">
                            {intl.formatMessage({ id: "backoffice.repositories.standardisation.transferlist.left.title" })}
                        </Typography>
                    </Grid>
                    <Grid item width={'calc((100% - 102px)/2'}>
                        <Typography component="p">
                            {intl.formatMessage({ id: "backoffice.repositories.standardisation.transferlist.right.title" })}
                        </Typography>
                    </Grid>
                </Grid> 

                <CvcTransferList 
                    filterLabel={intl.formatMessage({ id: "backoffice.repositories.standardisation.transferlist.filter.label" })}
                    showActions={showActions}
                    items={referenceDataList}
                    selectedItems={selectedData}
                    onChange={onChange}
                    showReloadData={true}
                    onReloadData={onReloadData}                
                    listHeight={440}
                />
            </Grid>

            <Grid item width='140px'>
                <Button variant='contained' disabled={disabledStandardizeBtn} onClick={onStandardize}>
                    {intl.formatMessage({ id: "backoffice.repositories.standardisation.btn.standardise" })}
                </Button>
            </Grid>
        </Grid>
    )
}

const DestinationPanel = ({ disabledValidateBtn, selectedReferenceTable, selectedData, selectedDestination, onSelectDestination, onValidate, onCancel }) => {
    
    const intl = useIntl();
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
   
    const selectedValue = selectedDestination?.label?.replace(/\s*\(.*?\)\s*/g, '');

    const handleClickValidateStandardization = async () => {
        setOpenConfirmDialog(true);
    }

    const handleCloseConfirmDialog = () => {
        setOpenConfirmDialog(false);
    }

    const handleConfirmAction = () => {
        setOpenConfirmDialog(false);
        onValidate();
    }

    const handleInputUserOption = (inputValue) => { 
        return { listCode: selectedReferenceTable.value, label: inputValue };
    }

    const RightFormPanel = () => {
        return (
            <Grid container direction='column' sx={{ rowGap: 2, width: '100%', height: '100%' }}
                    justifyContent={'start'} justifyItems={'start'}
                    alignContent={'start'} alignItems={'start'}
                >
                <Grid item alignContent={'center'}>
                    <Typography component="p">
                        {intl.formatMessage({ id: "backoffice.repositories.standardisation.autocomplete.destination" })}
                    </Typography>
                </Grid>
                <Grid item>                    
                    <CvcAutocompleteWithAdd
                        label={intl.formatMessage({ id: "backoffice.repositories.standardisation.autocomplete.destination" })}
                        sx={{ width: 300 }}
                        value={selectedDestination}
                        options={selectedData}
                        onChange={onSelectDestination}
                        getOptionLabel={(option) => option.label}
                        getInputUserOption={handleInputUserOption}                    
                    />
                </Grid> 
                <Grid container gap={2}>
                    <Grid item>
                        <Button variant='contained' disabled={disabledValidateBtn} onClick={handleClickValidateStandardization}>
                            {intl.formatMessage({ id: "backoffice.repositories.standardisation.btn.validate" })}
                        </Button>
                        <CvcConfirmActionDialog 
                            open={openConfirmDialog} 
                            title={intl.formatMessage({id: "backoffice.repositories.standardisation.dialog.confirmReplace.title"})} 
                            contentText={intl.formatMessage({id: "backoffice.repositories.standardisation.dialog.confirmReplace.contentText"}, {selectedValue: selectedValue})} 
                            onClose={handleCloseConfirmDialog} 
                            cancelBtnLabel={intl.formatMessage({id: "backoffice.repositories.standardisation.dialog.confirmReplace.btn.cancel"})} 
                            onCancel={handleCloseConfirmDialog} 
                            actionBtnLabel={intl.formatMessage({id: "backoffice.repositories.standardisation.dialog.confirmReplace.btn.replace"})} 
                            onAction={handleConfirmAction}  
                        />
                    </Grid>
                    <Grid item>
                        <Button variant='outlined' onClick={onCancel}>
                            {intl.formatMessage({ id: "backoffice.repositories.standardisation.btn.cancel" })}
                        </Button>
                    </Grid>         
                </Grid>
            </Grid>
        )
    }

    return (
        <Grid container gap={2}
            sx={{ width: '100%', height: '100%', marginTop: '20px' }}
        >                
            <Grid item xs={5}>
                <Typography component="p">
                    {intl.formatMessage({ id: "backoffice.repositories.standardisation.list.selectedData" })}
                </Typography>
                <CvcListVirtualized 
                    items={selectedData} 
                />
            </Grid>
            <Grid item xs={6} width={"100%"} height={"100%"}>
                <RightFormPanel />
            </Grid>
        </Grid>
    )
}

export default StandardizeReferenceDatasTab;