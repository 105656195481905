const { default: courseService } = require("services/api/courseService")
const { GROUP } = require("utils/constants/back-office-constants")

export const getPeriodLabel = (intl, period) => {
    return period ? intl.formatMessage({
        id: courseService.getLabelKeyByGroupAndCode(
            GROUP.period,
            period
        ),
    }) : "No Period"
}

export const getTypeLabel = (intl, type) => {
    return type ? intl.formatMessage({
        id: courseService.getLabelKeyByGroupAndCode(
            GROUP.courseType,
            type
        ),
    }) : "No Type"
}

export const getCodeLabel = (code) => {
    return code ? code : "No code"
}

export const getCourseLabel = (intl, course) => {
    return `${getPeriodLabel(intl, course.period)} - ${getTypeLabel(intl, course.type)} - ${getCodeLabel(course.code)} - ${course.title}`;
}
